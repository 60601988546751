import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { usePagination, useRole, useUploadFile } from 'shared/hooks';
import {
  GraphJinItemExtraSubClassDefaultInsertInput,
  GraphJinItemExtraSubClassDefaultQuery,
  GraphJinItemExtraSubClassDefaultUpdateMutation,
  GraphJinItemExtraSubClassDefaultUpdateMutationVariables,
  GraphJinItemSubClassQuery,
  ItemExtraSubClassDefaultUploadResult,
  QueryGraphJinItemExtraSubClassDefaultArgs,
  QueryGraphJinItemSubClassArgs,
} from 'shared/types';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';
import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';
import { EXCEL_FILE_TYPES } from 'shared/tools/excel/constants';
import { isFirstLoad } from 'shared/utils';

import { ItemExtraSubClassDefaultRow, ItemSubClassRow, MappedRow, SetupResult } from '../types';
import {
  graphJinItemExtraSubClassDefaultQuery,
  graphJinItemExtraSubClassDefaultUpdateMutation,
  graphJinItemSubClassQuery,
  itemExtraSubClassDefaultUploadMutation,
} from '../operations';
import { mapDataToRows } from '../mappers/mapDataToRows';
import { constants } from '../constants';
import { mapRowsForDownload } from '../mappers/mapRowsForDownload';
import { downloadTemplate } from '../utils/downloadTemplate';
import { downloadUploadResult } from '../utils/downloadUploadResult';

import { useColumns } from './useColumns';

const defaultItemExtraSubClassDefaults: ItemExtraSubClassDefaultRow[] = [];
const defaultItemSubClasses: ItemSubClassRow[] = [];

export const useSetup = (): SetupResult => {
  // Initial setup & states
  const role = useRole();
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination } = usePagination(
    constants.rowsPerPage,
  );
  // Queries
  const {
    data: itemExtraSubClassDefaultData,
    loading: itemExtraSubClassDefaultLoading,
    client,
    networkStatus: itemExtraSubClassDefaultNetworkStatus,
  } = useQuery<GraphJinItemExtraSubClassDefaultQuery, QueryGraphJinItemExtraSubClassDefaultArgs>(
    graphJinItemExtraSubClassDefaultQuery,
    {
      variables: {
        filter: {},
        pagination,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    data: itemSubClassData,
    loading: itemSubClassLoading,
    networkStatus: itemSubClassNetworkStatus,
  } = useQuery<GraphJinItemSubClassQuery, QueryGraphJinItemSubClassArgs>(
    graphJinItemSubClassQuery,
    {
      variables: {
        filter: {},
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  // Process query results
  const itemExtraSubClassDefaults =
    itemExtraSubClassDefaultData?.graphJinItemExtraSubClassDefault ??
    defaultItemExtraSubClassDefaults;

  const totalCount = itemExtraSubClassDefaults.length;

  const itemSubClasses = itemSubClassData?.graphJinItemSubClass ?? defaultItemSubClasses;

  const mappedData: MappedRow[] = useMemo(
    () => mapDataToRows(itemExtraSubClassDefaults, itemSubClasses),
    [itemExtraSubClassDefaults, itemSubClasses],
  );

  // Mutations
  const [updateItemExtraSubClassDefault, { loading: updateItemExtraSubClassDefaultLoading }] =
    useMutation<
      GraphJinItemExtraSubClassDefaultUpdateMutation,
      GraphJinItemExtraSubClassDefaultUpdateMutationVariables
    >(graphJinItemExtraSubClassDefaultUpdateMutation, {
      notifyOnNetworkStatusChange: true,
    });

  // Callbacks
  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    async (...updateArgs) => {
      const [, columnName, newValue, row, ,] = updateArgs;

      const updateRow = {
        pullByDate: row.pullByDate,
        markdownDays: row.markdownDays,
        captureExpiry: row.captureExpiry,
        acceptableShelfLife: row.acceptableShelfLife,
        packingConstraint: row.packingConstraint,
        [columnName]: newValue,
      };

      await updateItemExtraSubClassDefault({
        variables: {
          id: row.id,
          update: updateRow as GraphJinItemExtraSubClassDefaultInsertInput,
        },
      });
    },
    [updateItemExtraSubClassDefault],
  );

  const onDataGridDownloadButtonClick = useCallback(() => {
    const downloadData = mapRowsForDownload(mappedData);
    const exportInput = {
      fileName: `Item Extra Sub Class Default (${new Date().toLocaleString()})`,
      sheets: [
        {
          sheetName: 'data',
          tables: [
            {
              headers: [
                constants.download.columns.headers.department,
                constants.download.columns.headers.class,
                constants.download.columns.headers.subClass,
                constants.download.columns.headers.pullByDate,
                constants.download.columns.headers.markdownDays,
                constants.download.columns.headers.captureExpiry,
                constants.download.columns.headers.acceptableShelfLife,
                constants.download.columns.headers.packingConstraint,
              ],
              rows: downloadData,
            },
          ],
        },
      ],
    };
    exportXlsxFile(exportInput);
  }, [mapRowsForDownload, mappedData, exportXlsxFile]);

  const uploadFileProps = useUploadFile({
    mutation: itemExtraSubClassDefaultUploadMutation,
    client,
    evictCacheForQueryName: 'graphJinItemExtraSubClassDefault',
    fileType: 'excel',
    acceptedFileTypes: EXCEL_FILE_TYPES,
    templates: [
      {
        name: 'ITEM EXTRA SUB CLASS DEFAULT TEMPLATE',
        downloadCallback: downloadTemplate,
      },
    ],
    onDownloadResult: result =>
      downloadUploadResult(result as unknown as ItemExtraSubClassDefaultUploadResult),
  });

  // Bootstrap columns
  const columns = useColumns({ role });

  return {
    columns,
    data: mappedData,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData:
      itemExtraSubClassDefaultLoading ||
      updateItemExtraSubClassDefaultLoading ||
      itemSubClassLoading,
    showMainLoader:
      isFirstLoad(itemExtraSubClassDefaultNetworkStatus) || isFirstLoad(itemSubClassNetworkStatus),
    onDataGridDownloadButtonClick,
    uploadFileProps,
  };
};
