import { FC } from 'react';
import Chip from '@mui/material/Chip';

import { valueToLabel } from 'shared/components/NewestSelect/shared/utils';
import { Placeholder } from 'shared/components/NewestSelect/shared/components/Placeholder';

import { ButtonContentProps } from './types';
import styles from './ButtonContent.module.css';

const ButtonContent: FC<ButtonContentProps> = ({
  disabled,
  options,
  placeholder,
  selectedOptions,
  maxNumberOfSelectedOptionsToShow = 1,
}) => (
  <>
    {selectedOptions.length ? (
      <>
        {selectedOptions.slice(0, maxNumberOfSelectedOptionsToShow).map(option => (
          <Chip
            key={option.toString()}
            className={styles.chip}
            label={valueToLabel(option, options)}
            size="small"
          />
        ))}
        {selectedOptions.length > maxNumberOfSelectedOptionsToShow && (
          <Chip
            className={styles.chip}
            label={`+${selectedOptions.length - maxNumberOfSelectedOptionsToShow}`}
            size="small"
          />
        )}
      </>
    ) : (
      <Placeholder disabled={disabled} placeholder={placeholder} />
    )}
  </>
);

export { ButtonContent };
