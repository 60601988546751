/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DealManagement } from 'pages/DealManagement';
import { DealManagementDetail } from 'pages//DealManagementDetail';
import { BOM } from 'pages/BOM';
import { CostManagement } from 'pages/CostManagement';
import { CostManagement as CostManagementV2 } from 'pages/CostManagementV2';
import { DestinationAssortment } from 'pages/DestinationAssortment';
import { Item } from 'pages/Item';
import { ItemSource } from 'pages/ItemSource';
import { LocationPage } from 'pages/Location';
import { SourceDestinationPage } from 'pages/SourceDestination';
import { SupplierAssortment } from 'pages/SupplierAssortment';
import { Suppliers } from 'pages/Suppliers';
import { TransportationLane } from 'pages/TransportationLane';
import { UserDefinedAttribute } from 'pages/UserDefinedAttribute';
import { Menu } from 'shared/components/Menu';
import { Resources } from 'pages/Resources';
import { Root } from 'pages/Root';
import {
  PAGE_DEAL_MANAGEMENT,
  PAGE_DEAL_MANAGEMENT_DETAIL,
  PAGE_ITEM_EXTRA_SUB_CLASS_DEFAULT,
  PAGE_VENDOR_COST_MANAGEMENT_DEFAULT,
} from 'shared/constants/access';
import { ItemExtraSubClassDefault } from 'pages/ItemExtraSubClassDefault/ItemExtraSubClassDefault';
import { VendorCostManagementDefault } from 'pages/VendorCostManagementDefault/VendorCostManagementDefault';

import { useSetup } from './hooks';
import { SecureRoute } from './components/SecureRoute';

const App: FC = () => {
  const { unprocessedRecordsCount, userPermissions } = useSetup();
  return (
    <Grid container wrap="nowrap">
      <Menu unprocessedRecordsCount={unprocessedRecordsCount} />
      <Grid item overflow="hidden" xs>
        <Switch>
          <Route component={Root} exact path="/" />
          <SecureRoute
            component={LocationPage}
            path="/location"
            userPermissions={userPermissions}
          />
          <SecureRoute component={Item} exact path="/item" userPermissions={userPermissions} />
          <SecureRoute
            component={SourceDestinationPage}
            path="/source-destination"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={UserDefinedAttribute}
            path="/user-defined-attribute"
            userPermissions={userPermissions}
          />
          <SecureRoute component={BOM} path="/bom/:id/:extId" userPermissions={userPermissions} />
          <SecureRoute
            component={DestinationAssortment}
            path="/destination-assortment"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={TransportationLane}
            path="/transportation-lane"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={ItemSource}
            path="/item-source"
            userPermissions={userPermissions}
          />
          <SecureRoute component={Suppliers} path="/suppliers" userPermissions={userPermissions} />
          <SecureRoute
            component={SupplierAssortment}
            path="/supplier-assortment"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={CostManagement}
            path="/cost-management"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={CostManagementV2}
            path="/vendor-cost-management"
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={DealManagement}
            exact
            path={PAGE_DEAL_MANAGEMENT}
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={DealManagementDetail}
            exact
            path={PAGE_DEAL_MANAGEMENT_DETAIL}
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={ItemExtraSubClassDefault}
            exact
            path={PAGE_ITEM_EXTRA_SUB_CLASS_DEFAULT}
            userPermissions={userPermissions}
          />
          <SecureRoute
            component={VendorCostManagementDefault}
            exact
            path={PAGE_VENDOR_COST_MANAGEMENT_DEFAULT}
            userPermissions={userPermissions}
          />
          <SecureRoute component={Resources} path="/resources" userPermissions={userPermissions} />
        </Switch>
      </Grid>
    </Grid>
  );
};

export { App };
