import { useSetup } from './hooks/useSetup';
import { VendorCostManagementDefaultView } from './VendorCostManagementDefaultView';

const VendorCostManagementDefault = () => {
  const props = useSetup();

  return <VendorCostManagementDefaultView {...props} />;
};

export { VendorCostManagementDefault };
