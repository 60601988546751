import { ColumnType } from 'shared/tools/excel/constants';

export const constants = {
  rowsPerPage: 1000,
  download: {
    columns: {
      headers: {
        department: {
          displayName: 'Department',
          type: ColumnType.Text,
          width: 20,
        },
        class: {
          displayName: 'Class',
          type: ColumnType.Text,
          width: 30,
        },
        subClass: {
          displayName: 'Sub Class',
          type: ColumnType.Text,
          width: 45,
        },
        pullByDate: {
          displayName: 'Pull By Date (days)',
          type: ColumnType.Number,
        },
        markdownDays: {
          displayName: 'Bevmo Markdown (days)',
          type: ColumnType.Number,
        },
        captureExpiry: {
          displayName: 'Capture Expiry',
          type: ColumnType.Text,
        },
        acceptableShelfLife: {
          displayName: 'Acceptable Shelf Life (ASL)',
          type: ColumnType.Number,
        },
        packingConstraint: {
          displayName: 'Packing Constraint',
          type: ColumnType.Text,
        },
        error: {
          displayName: 'Error',
          type: ColumnType.Text,
          width: 45,
        },
      },
    },
  },
};
