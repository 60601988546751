import { useMutation, useQuery } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { mapRowToMutationArgs } from 'pages/SourceDestination/mappers';
import {
  CurrenciesQuery,
  GraphJinLeadTimeOverrideDeleteMutation,
  GraphJinLeadTimeOverrideDeleteMutationVariables,
  GraphJinLeadTimeOverrideInsertInput,
  GraphJinLeadTimeOverrideInsertMutation,
  GraphJinLeadTimeOverrideInsertMutationVariables,
  GraphJinLeadTimeOverrideQuery,
  GraphJinLeadTimeOverrideUpdateMutation,
  GraphJinLeadTimeOverrideUpdateMutationVariables,
  PackageTypesQuery,
  QueryGraphJinLeadTimeOverrideArgs,
  QuerySourceDestinationsArgs,
  SourceDestinationOptionsQuery,
  SourceDestinationQuery,
  SystemOfMeasuresQuery,
  UpdateSourceDestinationMutation,
  UpdateSourceDestinationMutationVariables,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  graphJinLeadTimeOverrideDeleteMutation,
  graphJinLeadTimeOverrideInsertMutation,
  graphJinLeadTimeOverrideQuery,
  graphJinLeadTimeOverrideUpdateMutation,
  sourceDestinationDownloadQuery,
  sourceDestinationOptionsQuery,
  sourceDestinationQuery,
  sourceDestinationUploadMutation,
  updateSourceDestinationMutation,
} from 'pages/SourceDestination/operations';
import {
  useDownload,
  useEditPage,
  useOnGridChange,
  usePagination,
  usePermissions,
  useRole,
  useUploadFile,
} from 'shared/hooks';
import { displayNameOptionMapper, isFirstLoad, mapToSelectOptions } from 'shared/utils';
import {
  LeadTimeOverrideRow,
  MappedRow,
  SetupResult,
  SourceDestinationRow,
  UnsubmittedRows,
} from 'pages/SourceDestination/types';
import { mapToSystemOfMeasuresOptions } from 'shared/utils/mapToSystemOfMeasuresOptions';
import { mapToMoqOrderingUomsOptions } from 'shared/utils/mapToMoqOrderingUomOptions';
import { mapToMovCurrenciesOptions } from 'shared/utils/mapToMovCurrenciesOptions';
import {
  currenciesQuery,
  packageTypesQuery,
  systemOfMeasuresQuery,
  usersQuery,
} from 'shared/operations/query';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';
import { RowHighlightColor } from 'shared/components/DataGrid/types';

import { mapDataToRows } from '../mappers/mapDataToRows';

import { useColumns } from './useColumns';

const skipColumns = ['containerUom' as keyof MappedRow];

const defaultSourceDestinations: SourceDestinationRow[] = [];
const defaultLeadTimeOverrides: LeadTimeOverrideRow[] = [];

const useSetup = (): SetupResult => {
  // Initial setup & states
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const userPermissions = usePermissions();
  const role = useRole();
  const [filter, setFilter] = useState<QuerySourceDestinationsArgs['filter']>({});

  const [leadTimeOverrideLoadSincePageChange, setLeadTimeOverrideLoadSincePageChange] = useState(0);

  const [currentPageLoaded, setCurrentPageLoaded] = useState(false);

  const [unsubmittedRows, setUnsubmittedRows] = useState<UnsubmittedRows>({});

  const {
    onCloseEditPage,
    onEditClick,
    selectedRows,
    setSelectedRows,
    showEditPage,
    triggerUncheckAllCheckboxes,
    uncheckAllCheckboxes,
  } = useEditPage<MappedRow>();

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<ReactNode>();
  const [deleteLeadTimeOverrideId, setDeleteLeadTimeOverrideId] = useState<MappedRow['id']>('');
  const [deletedLeadTimeOverrideIds, setDeletedLeadTimeOverrideIds] = useState<
    Array<MappedRow['id']>
  >([]);

  // Queries
  const { data: { currencies } = { currencies: [] }, networkStatus: currenciesNetworkStatus } =
    useQuery<CurrenciesQuery>(currenciesQuery);

  const {
    data: { packageTypes } = {
      packageTypes: [],
    },
    networkStatus: packageTypesNetworkStatus,
  } = useQuery<PackageTypesQuery>(packageTypesQuery);

  const {
    data: { systemOfMeasures } = {
      systemOfMeasures: [],
    },
    networkStatus: systemOfMeasuresNetworkStatus,
  } = useQuery<SystemOfMeasuresQuery>(systemOfMeasuresQuery);

  const {
    data: { containerUom } = { containerUom: [] },
    networkStatus: sourceDestinationOptionsNetworkStatus,
  } = useQuery<SourceDestinationOptionsQuery>(sourceDestinationOptionsQuery);

  const {
    data: { sourceDestinations: { destinations: data, totalCount } } = {
      sourceDestinations: {
        totalCount: 0,
        destinations: defaultSourceDestinations,
      },
    },
    loading,
    networkStatus: sourceDestinationQueryNetworkStatus,
    client,
  } = useQuery<SourceDestinationQuery, QuerySourceDestinationsArgs>(sourceDestinationQuery, {
    variables: {
      pagination,
      filter,
    },
    notifyOnNetworkStatusChange: true,
  });

  const leadTimeOverrideFilter = useMemo(
    () => ({
      transportationLaneId: data.map(({ id }) => id),
    }),
    [data],
  );

  const {
    data: leadTimeOverrideData,
    loading: leadTimeOverrideLoading,
    refetch: refetchLeadTimeOverride,
  } = useQuery<GraphJinLeadTimeOverrideQuery, QueryGraphJinLeadTimeOverrideArgs>(
    graphJinLeadTimeOverrideQuery,
    {
      variables: {
        filter: leadTimeOverrideFilter,
      },
      skip: !data?.length,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network', // Need to load from network every time to trigger auto expanding override rows
      onCompleted() {
        setLeadTimeOverrideLoadSincePageChange(oldValue => oldValue + 1);
      },
    },
  );

  const {
    data: { users } = { users: [] },
    networkStatus: usersNetworkStatus,
    loading: usersLoading,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery);

  const onDataGridDownloadButtonClick = useDownload({
    query: sourceDestinationDownloadQuery,
    queryResponseDataKey: 'sourceDestinationDownload',
    selectedRows,
    triggerUncheckAllCheckboxes,
  });

  // Process query results
  const userOptions = useMemo(() => mapToSelectOptions(users, displayNameOptionMapper), [users]);

  const currenciesOptions = useMemo(() => mapToMovCurrenciesOptions(currencies), [currencies]);

  const packageTypesOptions = useMemo(
    () => mapToMoqOrderingUomsOptions(packageTypes),
    [packageTypes],
  );

  const systemOfMeasuresOptions = useMemo(
    () => mapToSystemOfMeasuresOptions(systemOfMeasures),
    [systemOfMeasures],
  );

  const containerUomOptions = useMemo(() => mapToSelectOptions(containerUom), [containerUom]);

  // If there is no data, default to a constant.
  // Otherwise "mapDataToRows" will keep running upon re-render, causing performance issues
  const leadTimeOverrides = leadTimeOverrideData?.graphJinLeadTimeOverride?.length
    ? leadTimeOverrideData.graphJinLeadTimeOverride
    : defaultLeadTimeOverrides;

  const mappedData = useMemo(
    () => mapDataToRows(data, leadTimeOverrides, unsubmittedRows, deletedLeadTimeOverrideIds),
    [data, leadTimeOverrides, unsubmittedRows, deletedLeadTimeOverrideIds],
  );

  // Mutations
  const [updateSourceDestination, updateSourceDestinationMutationLoading] = useOnGridChange<
    MappedRow,
    UpdateSourceDestinationMutation,
    UpdateSourceDestinationMutationVariables
  >({
    data,
    mutation: updateSourceDestinationMutation,
    mapRowToMutationArgs,
    skipColumns,
    arbitraryMapperData: containerUom,
  });

  const [insertLeadTimeOverride, { loading: insertLeadTimeOverrideLoading }] = useMutation<
    GraphJinLeadTimeOverrideInsertMutation,
    GraphJinLeadTimeOverrideInsertMutationVariables
  >(graphJinLeadTimeOverrideInsertMutation, {
    notifyOnNetworkStatusChange: true,
    onCompleted() {
      void refetchLeadTimeOverride();
    },
  });

  const [updateLeadTimeOverride, { loading: updateLeadTimeOverrideLoading }] = useMutation<
    GraphJinLeadTimeOverrideUpdateMutation,
    GraphJinLeadTimeOverrideUpdateMutationVariables
  >(graphJinLeadTimeOverrideUpdateMutation, {
    notifyOnNetworkStatusChange: true,
  });

  const uploadFileProps = useUploadFile({
    templates: [
      {
        name: 'UPDATE SOURCE DESTINATION',
        downloadTemplateQuery: sourceDestinationDownloadQuery,
      },
      {
        name: 'UPDATE LEAD TIME OVERRIDE',
        base64String:
          'T3BDby9TaXRlIElELExvY2F0aW9uIElELE92ZXJyaWRlIFdlZWsgU3RhcnQgKE1vbmRheSksTW9uZGF5LFR1ZXNkYXksV2VkbmVzZGF5LFRodXJzZGF5LEZyaWRheSxTYXR1cmRheSxTdW5kYXkK',
      },
    ],
    mutation: sourceDestinationUploadMutation,
    client,
    evictCacheForQueryName: ['sourceDestinations', 'graphJinLeadTimeOverride'],
  });

  const [deleteLeadTimeOverride, { loading: deleteLeadTimeOverrideLoading }] = useMutation<
    GraphJinLeadTimeOverrideDeleteMutation,
    GraphJinLeadTimeOverrideDeleteMutationVariables
  >(graphJinLeadTimeOverrideDeleteMutation, {
    notifyOnNetworkStatusChange: true,
  });

  // Callbacks
  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    async (...updateArgs) => {
      const [
        ,
        columnName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        newValue,
        row,
        ,
      ] = updateArgs;

      if (!row.isLeadTimeOverrideRow) {
        updateSourceDestination(...updateArgs);
        return;
      }

      const weekStartDate = columnName === 'weekStartDate' ? newValue : row.weekStartDate;

      if (!weekStartDate || !row.transportationLaneId) return;

      if (row.isUnsubmitted) {
        setUnsubmittedRows(oldValue => {
          const newUnsubmittedRowsValue: UnsubmittedRows = { ...oldValue };
          delete newUnsubmittedRowsValue[row.parent!.id];
          return newUnsubmittedRowsValue;
        });
        await insertLeadTimeOverride({
          variables: {
            insert: [
              {
                transportationLaneId: row.transportationLaneId,
                weekStartDate,
              },
            ],
          },
        });
      } else {
        const updateRow = {
          transportationLaneId: row.transportationLaneId,
          weekStartDate: row.weekStartDate,
          monLeadTime: row.monLeadTime,
          tueLeadTime: row.tueLeadTime,
          wedLeadTime: row.wedLeadTime,
          thuLeadTime: row.thuLeadTime,
          friLeadTime: row.friLeadTime,
          satLeadTime: row.satLeadTime,
          sunLeadTime: row.sunLeadTime,
          [columnName]: newValue,
        };

        await updateLeadTimeOverride({
          variables: {
            id: row.id.split(':')[1],
            update: updateRow as GraphJinLeadTimeOverrideInsertInput,
          },
        });
      }
    },
    [updateSourceDestination, insertLeadTimeOverride, updateLeadTimeOverride, setUnsubmittedRows],
  );

  const updateFilter = useCallback((newFilter: QuerySourceDestinationsArgs['filter']) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
    resetPageIndex();
  }, []);

  const reset = useCallback(() => {
    resetPageIndex();
    setFilter({});
  }, []);

  const onOpenDeleteModalClick = useCallback(
    (id: MappedRow['id'], message: ReactNode) => {
      setShouldShowDeleteModal(true);
      setDeleteMessage(message);
      setDeleteLeadTimeOverrideId(id);
    },
    [setShouldShowDeleteModal, setDeleteMessage, setDeleteLeadTimeOverrideId],
  );

  const onCloseDeleteModalClick = useCallback(() => {
    setShouldShowDeleteModal(false);
    setDeleteMessage(undefined);
    setDeleteLeadTimeOverrideId('');
  }, [setShouldShowDeleteModal, setDeleteMessage, setDeleteLeadTimeOverrideId]);

  const onConfirmDeleteModalClick = useCallback(() => {
    if (!deleteLeadTimeOverrideId) return;

    void deleteLeadTimeOverride({
      variables: {
        deleting: true,
        id: deleteLeadTimeOverrideId,
      },
      update() {
        setDeletedLeadTimeOverrideIds(oldValue => [...oldValue, deleteLeadTimeOverrideId]);
      },
    });
    onCloseDeleteModalClick();
  }, [deleteLeadTimeOverrideId, deleteLeadTimeOverride, onCloseDeleteModalClick]);

  const highlightRowWithColor = useCallback((row: MappedRow) => {
    if (row.isCurrentWeek) return RowHighlightColor.Green;
    if (row.isOverridden) return RowHighlightColor.Yellow;
    return undefined;
  }, []);

  const shouldExpandByDefault = useCallback((row: MappedRow) => !!row.isOverridden, []);

  // This uses query load count per page to know when page has fully loaded
  // because DataGrid uses currentPageLoaded state to trigger auto expanding override rows
  useEffect(() => {
    setLeadTimeOverrideLoadSincePageChange(0);
    setCurrentPageLoaded(false);
  }, [pageIndex]);

  useEffect(() => {
    if (leadTimeOverrideLoadSincePageChange > 0 && !currentPageLoaded) {
      setCurrentPageLoaded(true);
    }
  }, [leadTimeOverrideLoadSincePageChange]);

  // Bootstrap columns
  const columns = useColumns({
    containerUomOptions,
    updateFilter,
    role,
    userOptions,
    currenciesOptions,
    packageTypesOptions,
    systemOfMeasuresOptions,
    setUnsubmittedRows,
    onOpenDeleteModalClick,
  });

  return {
    showEditPage,
    onEditClick,
    onCloseEditPage,
    selectedRows,
    uncheckAllCheckboxes,
    setSelectedRows,
    containerUomOptions,
    triggerUncheckAllCheckboxes,
    reset,
    columns,
    data: mappedData,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageSize,
    pageIndex,
    onChange,
    loadingMoreData:
      updateSourceDestinationMutationLoading ||
      loading ||
      usersLoading ||
      leadTimeOverrideLoading ||
      insertLeadTimeOverrideLoading ||
      updateLeadTimeOverrideLoading ||
      deleteLeadTimeOverrideLoading,
    onDataGridDownloadButtonClick,
    onCloseDeleteModalClick,
    onConfirmDeleteModalClick,
    shouldShowDeleteModal,
    deleteMessage,
    uploadFileProps,
    userPermissions,
    showMainLoader:
      isFirstLoad(sourceDestinationQueryNetworkStatus) ||
      isFirstLoad(sourceDestinationOptionsNetworkStatus) ||
      isFirstLoad(packageTypesNetworkStatus) ||
      isFirstLoad(currenciesNetworkStatus) ||
      isFirstLoad(systemOfMeasuresNetworkStatus) ||
      isFirstLoad(usersNetworkStatus),
    highlightRowWithColor,
    shouldExpandByDefault,
    currentPageLoaded,
  };
};

export { useSetup };
