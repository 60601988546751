import { constants } from '../constants';
import { MappedRow } from '../types';

export const mapRowsForDownload = (rows: MappedRow[]): Record<string, string>[] => {
  const rowsToDownload = rows.map(row => {
    const rowToDownload: Record<string, string> = {};
    rowToDownload[constants.download.columns.headers.department.displayName] = row.department;
    rowToDownload[constants.download.columns.headers.class.displayName] = row.class;
    rowToDownload[constants.download.columns.headers.subClass.displayName] = row.subClass;
    rowToDownload[constants.download.columns.headers.pullByDate.displayName] =
      row.pullByDate?.toString() ?? '';
    rowToDownload[constants.download.columns.headers.markdownDays.displayName] =
      row.markdownDays?.toString() ?? '';
    rowToDownload[constants.download.columns.headers.captureExpiry.displayName] = row.captureExpiry
      ? 'Yes'
      : 'No';
    rowToDownload[constants.download.columns.headers.acceptableShelfLife.displayName] =
      row.acceptableShelfLife?.toString() ?? '';
    rowToDownload[constants.download.columns.headers.packingConstraint.displayName] =
      row.packingConstraint;
    return rowToDownload;
  });

  return rowsToDownload;
};
