import { useMemo } from 'react';

import { FactoryCellNewestSelectMulti } from 'shared/components/DataGrid';
import { isReadOnlyRole } from 'shared/utils';

import { MappedRow, UseColumnsArgs, UseColumnsResult, UserAssignmentRow } from '../types';

export const useColumns = ({ role, userOptions }: UseColumnsArgs): UseColumnsResult =>
  useMemo(() => {
    const isReadOnly = isReadOnlyRole(role);
    const isReadOnlyFunc = () => isReadOnly;

    return [
      {
        Header: 'Department',
        accessor: 'departmentName',
        width: 200,
      },
      {
        Header: 'Class',
        accessor: 'className',
        width: 300,
      },
      {
        Header: 'Merchandising Owner',
        accessor: 'userAssignments',
        Cell: FactoryCellNewestSelectMulti<MappedRow, string, UserAssignmentRow[]>({
          options: userOptions,
          isDisabled: isReadOnlyFunc,
          pickValue: value => value.map(({ userId }) => userId),
          maxNumberOfSelectedOptionsToShow: 6,
        }),
      },
    ];
  }, [role, userOptions]);
