import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';

import { downloadBase64StringAsFile } from 'shared/utils';

import type { UploadFileSummary, UseUploadFileArgs, UseUploadFileResult } from './types';
import {
  itemAssortmentTemplate,
  itemSourceBreakableTemplate,
  itemSourceCreateCasepackTemplate,
  itemSourceDeleteCasepackTemplate,
  itemSourceDimsTemplate,
  itemSourceLotSizeTemplate,
  itemSourceOrderingTemplate,
  itemSourceQtyTemplate,
  itemSourceSupplierItemNumberTemplate,
  itemSourceUPCTemplate,
  itemStopBuyingTemplate,
} from './customTemplateBase64Content';
import { useOnDownloadTemplate } from './useOnDownloadTemplate';

const useUploadFile = <MutationResult extends { [key: string]: UploadFileSummary }>({
  client,
  customFilter,
  downloadTemplateQuery,
  evictCacheForQueryName,
  fileType,
  mutation,
  view,
  templates,
  ...props
}: UseUploadFileArgs): UseUploadFileResult => {
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [
    uploadFileMutation,
    {
      called: isUploadFileCalled,
      data,
      loading: uploadFileLoading,
      reset: resetUploadFileMutation,
    },
  ] = useMutation<MutationResult, { input: { file: any } }>(mutation);

  const uploadedFile = useMemo(() => (data ? Object.values(data)[0] : null), [data]);

  const onDownloadTemplate =
    downloadTemplateQuery &&
    useOnDownloadTemplate({
      downloadTemplateQuery,
      customFilter,
      fileType,
      view,
    });

  if (templates?.length) {
    for (let i = 0; i < templates.length; i++) {
      const template = templates[i];
      if (template.downloadTemplateQuery) {
        template.downloadCallback = useOnDownloadTemplate({
          downloadTemplateQuery: template.downloadTemplateQuery,
          customFilter,
          fileType,
          view,
        });
      } else if (template.base64String) {
        template.downloadCallback = () => {
          downloadBase64StringAsFile(template.base64String!, template.name);
        };
      }
    }
  }

  const onDownloadCustomTemplate = (templateName: string) => {
    switch (templateName) {
      // /item templates
      case 'UPDATE ALL ITEM FIELDS': {
        if (onDownloadTemplate) {
          void onDownloadTemplate();
        }
        break;
      }
      case 'UPDATE ONLY ITEM ASSORTMENT': {
        downloadBase64StringAsFile(itemAssortmentTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY ITEM STOP BUYING': {
        downloadBase64StringAsFile(itemStopBuyingTemplate, templateName);
        break;
      }
      // /item-source templates
      case 'CREATE NEW CASEPACK (ALL FIELDS)': {
        downloadBase64StringAsFile(itemSourceCreateCasepackTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY SUPPLIER ITEM NUMBER ON EXISTING CASEPACK': {
        downloadBase64StringAsFile(itemSourceSupplierItemNumberTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY UPC ON EXISTING CASEPACK': {
        downloadBase64StringAsFile(itemSourceUPCTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY DIMENSIONS ON EXISTING CASEPACK': {
        downloadBase64StringAsFile(itemSourceDimsTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY PACKAGING QTY ON EXISTING CASEPACK': {
        downloadBase64StringAsFile(itemSourceQtyTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY BREAKABLE FIELDS ON EXISTING ITEM SOURCE': {
        downloadBase64StringAsFile(itemSourceBreakableTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY ORDERING FIELDS ON EXISTING ITEM SOURCE': {
        downloadBase64StringAsFile(itemSourceOrderingTemplate, templateName);
        break;
      }
      case 'UPDATE ONLY LOT SIZE FIELDS ON EXISTING ITEM SOURCE': {
        downloadBase64StringAsFile(itemSourceLotSizeTemplate, templateName);
        break;
      }
      case 'DELETE A CASEPACK': {
        downloadBase64StringAsFile(itemSourceDeleteCasepackTemplate, templateName);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onUploadFile = useCallback(
    (file: File) =>
      uploadFileMutation({
        variables: { input: { file } },
      }),
    [uploadFileMutation],
  );

  const onOpenUploadFileModal = useCallback(() => setShowUploadFileModal(true), []);

  const onCloseUploadFileModal = useCallback(() => {
    setShowUploadFileModal(false);

    if (isUploadFileCalled && (uploadedFile?.success || uploadedFile?.summary?.success)) {
      const list =
        typeof evictCacheForQueryName === 'string'
          ? [evictCacheForQueryName]
          : evictCacheForQueryName;

      list.forEach(name => {
        client.cache.evict({
          fieldName: name,
          broadcast: true,
        });
      });
      client.cache.gc();
    }

    if (isUploadFileCalled) {
      resetUploadFileMutation();
    }
  }, [resetUploadFileMutation, isUploadFileCalled, uploadedFile]);

  return useMemo(
    () => ({
      onDownloadTemplate,
      onDownloadCustomTemplate,
      isOpen: showUploadFileModal,
      onOpenUploadFileModal,
      onClose: onCloseUploadFileModal,
      uploadedFile,
      uploadFileLoading,
      onUploadFile,
      fileType,
      templates,
      ...props,
    }),
    [
      showUploadFileModal,
      onOpenUploadFileModal,
      onCloseUploadFileModal,
      uploadedFile,
      uploadFileLoading,
      onUploadFile,
      fileType,
      templates,
    ],
  );
};

export { useUploadFile };
