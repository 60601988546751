import { ItemExtraSubClassDefaultUploadResult } from 'shared/types';
import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';

import { constants } from '../constants';

export const downloadUploadResult = (uploadResult: ItemExtraSubClassDefaultUploadResult) => {
  const { rows } = uploadResult;

  const downloadRows: Array<Record<string, unknown>> = [];
  rows.forEach(row => {
    downloadRows.push({
      [constants.download.columns.headers.department.displayName]: row.department ?? '',
      [constants.download.columns.headers.class.displayName]: row.class ?? '',
      [constants.download.columns.headers.subClass.displayName]: row.subClass ?? '',
      [constants.download.columns.headers.pullByDate.displayName]: row.pullByDate ?? '',
      [constants.download.columns.headers.markdownDays.displayName]: row.markdownDays ?? '',
      [constants.download.columns.headers.captureExpiry.displayName]: row.captureExpiry ?? '',
      [constants.download.columns.headers.acceptableShelfLife.displayName]:
        row.acceptableShelfLife ?? '',
      [constants.download.columns.headers.packingConstraint.displayName]:
        row.packingConstraint ?? '',
      [constants.download.columns.headers.error.displayName]: row.error ?? '',
    });
  });

  const input = {
    fileName: `Item Extra Sub Class Default Upload Result (${new Date().toLocaleString()})`,
    sheets: [
      {
        sheetName: 'data',
        tables: [
          {
            headers: [
              constants.download.columns.headers.department,
              constants.download.columns.headers.class,
              constants.download.columns.headers.subClass,
              constants.download.columns.headers.pullByDate,
              constants.download.columns.headers.markdownDays,
              constants.download.columns.headers.captureExpiry,
              constants.download.columns.headers.acceptableShelfLife,
              constants.download.columns.headers.packingConstraint,
              constants.download.columns.headers.error,
            ],
            rows: downloadRows,
          },
        ],
      },
    ],
  };

  return exportXlsxFile(input);
};
