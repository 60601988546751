import { Typography } from '@mui/material';

import { UploadFileSummary } from 'shared/hooks';
import { CostManagementFileRow } from 'shared/types';

import { downloadColumnNames } from '../mappers/mapRowsForDownload';
import { exportCostManagementXlsxFile } from '../utils/exportCostManagementXlsxFile';

const getUploadActionsModalUI = () => (
  <>
    You can upload a file to approve/reject/delete costs in bulk.
    <br />
    <ol>
      <li>Download costs in excel format using the download button.</li>
      <li>
        Add <Typography variant="bold1">APPROVE</Typography>,{' '}
        <Typography variant="bold1">REJECT</Typography>,{' '}
        <Typography variant="bold1">DELETE</Typography> values in the status column (case
        insensitive). All other values are invalid.
      </li>
      <li>For all rejected rows, add required note to the Note column</li>
      <li>Leave other columns untouched</li>
      <li>Upload the file.</li>
    </ol>
  </>
);

const getOnDownloadUploadActionsResultCallback =
  (
    hideIsDisabledColumn: boolean,
    hideCanAutoApproveColumn: boolean,
    shouldShowParentCompanyColumn: boolean,
  ) =>
  (result: Record<string, unknown>) => {
    const { rows } = result as unknown as UploadFileSummary;
    const typedRows = rows as Array<CostManagementFileRow>;
    const rowsToDownload = typedRows.map(row => {
      const rowToDownload: Record<string, string> = {};
      rowToDownload[downloadColumnNames.itemDepartment] = row.itemDepartment ?? '';
      rowToDownload[downloadColumnNames.itemClass] = row.itemClass ?? '';
      rowToDownload[downloadColumnNames.itemId] = row.itemExtID ?? '';
      rowToDownload[downloadColumnNames.itemName] = row.itemName ?? '';
      rowToDownload[downloadColumnNames.caseCount] = row.caseCount?.toString() ?? '';
      rowToDownload[downloadColumnNames.eachUPC] = row.eachUpc ?? '';
      rowToDownload[downloadColumnNames.caseUPC] = row.caseUpc ?? '';
      rowToDownload[downloadColumnNames.eachVIN] = row.eachVin ?? '';
      rowToDownload[downloadColumnNames.caseVIN] = row.caseVin ?? '';
      rowToDownload[downloadColumnNames.status] = row.status ?? '';
      rowToDownload[downloadColumnNames.eachCostValidation] = row.eachCostValidation ?? '';
      rowToDownload[downloadColumnNames.caseCostValidation] = row.caseCostValidation ?? '';
      if (!hideCanAutoApproveColumn) {
        rowToDownload[downloadColumnNames.canAutoApprove] = row.canAutoApprove ?? '';
      }
      rowToDownload[downloadColumnNames.note] = row.notes ?? '';
      if (!hideIsDisabledColumn) {
        rowToDownload[downloadColumnNames.isDisabled] = row.isDisabled ?? '';
      }
      rowToDownload[downloadColumnNames.effectiveDate] = row.effectiveDate ?? '';
      rowToDownload[downloadColumnNames.eachCost] = row.eachCost?.toString() ?? '';
      rowToDownload[downloadColumnNames.caseCost] = row.caseCost?.toString() ?? '';
      rowToDownload[downloadColumnNames.currency] = row.currency ?? '';
      rowToDownload[downloadColumnNames.supplierName] = row.supplierName ?? '';
      rowToDownload[downloadColumnNames.supplierId] = row.supplierExtID ?? '';
      rowToDownload[downloadColumnNames.opcoName] = row.opcoName ?? '';
      rowToDownload[downloadColumnNames.opcoId] = row.opcoID ?? '';
      rowToDownload[downloadColumnNames.opcoStateProvinceRegion] = row.opcoState ?? '';
      rowToDownload[downloadColumnNames.locationName] = row.locationName ?? '';
      rowToDownload[downloadColumnNames.locationId] = row.locationID ?? '';
      rowToDownload[downloadColumnNames.locationAddress] = row.locationAddress ?? '';
      rowToDownload[downloadColumnNames.locationCity] = row.locationCity ?? '';
      rowToDownload[downloadColumnNames.locationStateProvinceRegion] = row.locationState ?? '';
      rowToDownload[downloadColumnNames.locationPostalCode] = row.locationPostalCode ?? '';
      if (shouldShowParentCompanyColumn) {
        rowToDownload[downloadColumnNames.parentCompany] = row.parentCompany ?? '';
      }
      rowToDownload[downloadColumnNames.error] = row.error ?? '';

      return rowToDownload;
    });
    exportCostManagementXlsxFile(
      rowsToDownload,
      `Vendor Cost Management Actions Upload Result (${new Date().toLocaleString()})`,
      'cost',
    );
  };

export { getUploadActionsModalUI, getOnDownloadUploadActionsResultCallback };
