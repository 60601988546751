import { FC, useCallback, useMemo } from 'react';
import { PropGetters, useCombobox, useMultipleSelection } from 'downshift';

import { SelectView } from 'shared/components/NewestSelect/shared/components/SelectView';
import { itemToString } from 'shared/components/NewestSelect/shared/utils';
import { SelectOption as SelectOptionType } from 'shared/components/NewestSelect/shared/types';
import {
  useLastRenderedList,
  useOnIsOpenChange,
  useSearch,
} from 'shared/components/NewestSelect/shared/hooks';
import { stateReducer } from 'shared/components/NewestSelect/shared/props';

import { ButtonContent, SelectOption } from './components';
import { SelectMultiProps } from './types';

const SelectMulti: FC<SelectMultiProps> = ({
  onChange,
  onClose,
  onOpen,
  initialSelectedOptions = [],
  options,
  placeholder,
  open,
  disabled = false,
  maxNumberOfSelectedOptionsToShow,
}) => {
  const { clearSearch, filteredOptions, onInputValueChange, searchInputValue } = useSearch({
    options,
  });

  const onIsOpenChange = useOnIsOpenChange({ onOpen, onClose, clearSearch });

  const {
    addSelectedItem,
    getDropdownProps,
    removeSelectedItem,
    selectedItems: selectedOptions,
  } = useMultipleSelection({
    initialSelectedItems: initialSelectedOptions,
    onSelectedItemsChange({ selectedItems = [] }) {
      onChange?.(selectedItems);
    },
  });

  const {
    closeMenu,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    isOpen,
  } = useCombobox({
    isOpen: open,
    inputValue: searchInputValue,
    items: filteredOptions,
    itemToString,
    selectedItem: null,
    stateReducer,
    onStateChange: ({ inputValue, selectedItem, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          onInputValueChange({ inputValue, selectedItem, type });
          break;
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (selectedItem) {
            if (selectedOptions.includes(selectedItem.value)) {
              removeSelectedItem(selectedItem.value);
            } else {
              addSelectedItem(selectedItem.value);
            }
          }
          break;
        default:
          break;
      }
    },
    onIsOpenChange,
  });

  const getButtonProps = useCallback(
    (...args: Parameters<PropGetters<SelectOptionType>['getToggleButtonProps']>): any =>
      getToggleButtonProps({
        ...getDropdownProps({ preventKeyAction: isOpen, ...(args?.[0] || {}) }),
      }),
    [getToggleButtonProps, getDropdownProps],
  );

  const buttonContent = useMemo(
    () => (
      <ButtonContent
        disabled={disabled}
        maxNumberOfSelectedOptionsToShow={maxNumberOfSelectedOptionsToShow}
        options={options}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
      />
    ),
    [selectedOptions, options, placeholder, disabled],
  );

  const menuContent = useLastRenderedList({
    SelectOption,
    filteredOptions,
    getItemProps,
    isOpen,
    options,
    selectedValue: selectedOptions,
  });

  return (
    <SelectView
      buttonContent={buttonContent}
      closeMenu={closeMenu}
      disabled={disabled}
      getComboboxProps={getComboboxProps}
      getInputProps={getInputProps}
      getMenuProps={getMenuProps}
      getToggleButtonProps={getButtonProps}
      open={isOpen}
      searchInputValue={searchInputValue}
    >
      {menuContent}
    </SelectView>
  );
};

export { SelectMulti };
