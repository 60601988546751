import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { EXCEL_FILE_TYPES } from 'shared/tools/excel/constants';
import { useEditPage, usePagination, useRole, useUploadFile } from 'shared/hooks';
import { DealUploadResult, DealsQuery, DealsQueryVariables } from 'shared/types';
import { ROLE_EXTERNAL_USER } from 'shared/constants/access';
import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';

import { dealUploadMutation, queryDeals } from '../operations';
import { mapDataToRows } from '../mappers';
import { MappedRow, Row, SetupResult } from '../types';
import { downloadDealUploadResult } from '../utils';
import { constants } from '../constants';
import { mapRowsForDownload } from '../mappers/mapRowsForDownload';
import { downloadStrikethroughTemplateExcel } from '../utils/downloadStrikethroughTemplateExcel';

import { useColumns } from './useColumns';

const defaultItems: Row[] = [];

export const useSetup = (): SetupResult => {
  const role = useRole();
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination } = usePagination();
  const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState(false);
  const { selectedRows, setSelectedRows, triggerUncheckAllCheckboxes, uncheckAllCheckboxes } =
    useEditPage<MappedRow>();

  const {
    data: { deals: { records: data, totalCount } } = {
      deals: {
        totalCount: 0,
        records: defaultItems,
      },
    },
    loading,
    client,
  } = useQuery<DealsQuery, DealsQueryVariables>(queryDeals, {
    variables: {
      pagination,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: () => {
      setIsFirstLoadingFinish(true);
    },
  });

  const mappedData = useMemo(() => mapDataToRows(data), [data]);

  const columns = useColumns();

  const uploadFileProps = useUploadFile({
    mutation: dealUploadMutation,
    client,
    evictCacheForQueryName: 'deals',
    fileType: 'excel',
    acceptedFileTypes: EXCEL_FILE_TYPES,
    templates: [
      {
        name: 'STRIKETHROUGH DEAL TEMPLATE',
        downloadCallback: downloadStrikethroughTemplateExcel,
      },
    ],
    onDownloadResult: result => downloadDealUploadResult(result as unknown as DealUploadResult),
  });

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const downloadDeals = (variables: DealsQueryVariables) =>
    client.query<DealsQuery, DealsQueryVariables>({
      query: queryDeals,
      variables,
      // no-cache required to prevent download missfires
      fetchPolicy: 'no-cache',
    });

  const onDataGridDownloadButtonClick = useCallback(async () => {
    const rowsToDownloadCount =
      selectedRows.length > 0
        ? selectedRows.length
        : Math.min(totalCount, constants.download.totalLimit);
    if (!rowsToDownloadCount) return;

    setIsDownloading(true);
    let paginationLimit = Math.min(rowsToDownloadCount, constants.download.paginationLimit);
    const promises = [];

    for (
      let offset = 0;
      offset < rowsToDownloadCount;
      offset += constants.download.paginationLimit
    ) {
      paginationLimit = Math.min(rowsToDownloadCount - offset, constants.download.paginationLimit);

      const variables = {
        filter: {
          id: selectedRows.map(row => row.original.id),
        },
        pagination: {
          limit: paginationLimit,
          offset,
        },
      };
      promises.push(downloadDeals(variables));
    }

    try {
      const results = await Promise.all(promises);
      const records = results.flatMap(result => result?.data?.deals?.records || []);
      const downloadRows = mapDataToRows(records);
      const downloadData = mapRowsForDownload(downloadRows);
      const exportInput = {
        fileName: `Deal Management (${new Date().toLocaleString()})`,
        sheets: [
          {
            sheetName: 'deal',
            tables: [
              {
                headers: [
                  constants.download.columns.header.offerNumber,
                  constants.download.columns.header.status,
                  constants.download.columns.header.parentCompany,
                  constants.download.columns.header.submitterName,
                  constants.download.columns.header.submitterEmail,
                  constants.download.columns.header.description,
                  constants.download.columns.header.type,
                  constants.download.columns.header.startDate,
                  constants.download.columns.header.endDate,
                  constants.download.columns.header.approvedBy,
                  constants.download.columns.header.approvedDate,
                  constants.download.columns.header.updatedDate,
                  constants.download.columns.header.updatedBy,
                  constants.download.columns.header.note,
                ],
                rows: downloadData,
              },
            ],
          },
        ],
      };
      exportXlsxFile(exportInput);
    } finally {
      triggerUncheckAllCheckboxes();
      setIsDownloading(false);
    }
  }, [
    selectedRows,
    totalCount,
    constants,
    setIsDownloading,
    downloadDeals,
    mapDataToRows,
    mapRowsForDownload,
    exportXlsxFile,
    triggerUncheckAllCheckboxes,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(loading || isDownloading);
  }, [loading, isDownloading]);

  return {
    isFirstLoadingFinish,
    data: mappedData,
    columns,
    totalCount,
    onPageChange,
    onRowsPerPageChange,
    pageIndex,
    pageSize,
    canUpload: ROLE_EXTERNAL_USER.has(role),
    uploadFileProps,
    onDataGridDownloadButtonClick,
    selectedRows,
    isLoading,
    setSelectedRows,
    uncheckAllCheckboxes,
  };
};
