import { FC } from 'react';

import { DataGrid } from 'shared/components/DataGrid';
import { DataGridPage } from 'shared/components/DataGridPage';
import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { ACTION_UPLOAD_FILE_SOURCE_DESTINATION } from 'shared/constants';
import { ConfirmModal } from 'shared/components/Modal/ConfirmModal';

import { SourceDestinationViewProps } from './types';

const SourceDestinationView: FC<SourceDestinationViewProps> = ({
  onDataGridDownloadButtonClick,
  onEditClick,
  selectedRows,
  triggerUncheckAllCheckboxes,
  uploadFileProps,
  userPermissions,
  shouldShowDeleteModal,
  deleteMessage,
  onCloseDeleteModalClick,
  onConfirmDeleteModalClick,
  highlightRowWithColor,
  shouldExpandByDefault,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          hasAccessToUploadFile={userPermissions.has(ACTION_UPLOAD_FILE_SOURCE_DESTINATION)}
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          title="Source Destination"
          uploadFileProps={uploadFileProps}
        />
      }
      onEditClick={onEditClick}
      selectedRows={selectedRows}
      triggerUncheckAllCheckboxes={triggerUncheckAllCheckboxes}
    >
      <DataGrid
        {...props}
        disableOnLoadingMoreData
        enableExpanding
        enableRowSelection
        enableRowState
        highlightRowWithColor={highlightRowWithColor}
        shouldExpandByDefault={shouldExpandByDefault}
      />
    </DataGridPage>
    <ConfirmModal
      confirmMessage={deleteMessage}
      isOpen={shouldShowDeleteModal}
      onClose={onCloseDeleteModalClick}
      onConfirm={onConfirmDeleteModalClick}
    />
  </>
);

export { SourceDestinationView };
