import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { useRole } from 'shared/hooks';
import {
  GraphJinItemSupplierHierarchyQuery,
  GraphJinUserAssignmentDeleteMutation,
  GraphJinUserAssignmentDeleteMutationVariables,
  GraphJinUserAssignmentInsertMutation,
  GraphJinUserAssignmentInsertMutationVariables,
  Option,
  QueryGraphJinItemSupplierHierarchyArgs,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';
import { isFirstLoad } from 'shared/utils';
import { usersQuery } from 'shared/operations/query';
import { ROLE_ADMIN, ROLE_MANAGER, ROLE_PRICING } from 'shared/constants';

import { MappedRow, SetupResult } from '../types';
import {
  graphJinItemSupplierHierarchyQuery,
  graphJinUserAssignmentDeleteMutation,
  graphJinUserAssignmentInsertMutation,
} from '../operations';
import { mapDataToRows } from '../mappers/mapDataToRows';

import { useColumns } from './useColumns';

export const useSetup = (): SetupResult => {
  // Initial setup & states
  const role = useRole();

  // Queries
  const {
    data: itemSupplierHierarchyData,
    loading: itemSupplierHierarchyLoading,
    networkStatus: itemSupplierHierarchyNetworkStatus,
    refetch: refetchItemSupplierHierarchy,
  } = useQuery<GraphJinItemSupplierHierarchyQuery, QueryGraphJinItemSupplierHierarchyArgs>(
    graphJinItemSupplierHierarchyQuery,
    {
      variables: {
        filter: {
          opcoIdIsNull: true,
          supplierIdIsNull: true,
        },
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    data: usersData,
    loading: usersLoading,
    networkStatus: usersNetworkStatus,
  } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery, {
    variables: {
      filter: {
        roles: [ROLE_ADMIN, ROLE_MANAGER, ROLE_PRICING],
      },
    },
  });

  // Process query results
  const mappedData: MappedRow[] = useMemo(
    () =>
      itemSupplierHierarchyData?.graphJinItemSupplierHierarchy
        ? mapDataToRows(itemSupplierHierarchyData?.graphJinItemSupplierHierarchy)
        : [],
    [itemSupplierHierarchyData],
  );

  const userOptions = useMemo(() => {
    const options: Option<string, string>[] = [];

    usersData?.users?.forEach(user => {
      options.push({
        label: user.displayName,
        value: user.id,
      });
    });

    options.sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }, [usersData]);

  // Mutations
  const [insertUserAssignment, { loading: insertUserAssignmentLoading }] = useMutation<
    GraphJinUserAssignmentInsertMutation,
    GraphJinUserAssignmentInsertMutationVariables
  >(graphJinUserAssignmentInsertMutation, {
    notifyOnNetworkStatusChange: true,
  });

  const [deleteUserAssignment, { loading: deleteUserAssignmentLoading }] = useMutation<
    GraphJinUserAssignmentDeleteMutation,
    GraphJinUserAssignmentDeleteMutationVariables
  >(graphJinUserAssignmentDeleteMutation, {
    notifyOnNetworkStatusChange: true,
  });

  // Callbacks
  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(async (...updateArgs) => {
    const [, columnName, newValue, row, ,] = updateArgs;
    if (columnName === 'userAssignments') {
      const updatedUserIds = newValue as string[];

      const userIdsToAdd = updatedUserIds.filter(
        (value: string) => !row.userAssignments.some(({ userId }) => userId === value),
      );

      if (userIdsToAdd.length) {
        await insertUserAssignment({
          variables: {
            insert: userIdsToAdd.map(userId => ({
              userId,
              itemSupplierHierarchyId: row.id,
            })),
          },
        });
      }

      const idsToDelete = row.userAssignments
        .filter(({ userId }) => !updatedUserIds.includes(userId))
        .map(({ id }) => id);

      if (idsToDelete.length) {
        const deletePromises = idsToDelete.map(id =>
          deleteUserAssignment({
            variables: {
              id,
              deleting: true,
            },
          }),
        );

        await Promise.all(deletePromises);
      }

      if (userIdsToAdd.length || idsToDelete.length) {
        await refetchItemSupplierHierarchy();
      }
    }
  }, []);

  // Bootstrap columns
  const columns = useColumns({
    role,
    userOptions,
  });

  return {
    columns,
    data: mappedData,
    onChange,
    loadingMoreData:
      itemSupplierHierarchyLoading ||
      insertUserAssignmentLoading ||
      deleteUserAssignmentLoading ||
      usersLoading,
    showMainLoader:
      isFirstLoad(itemSupplierHierarchyNetworkStatus) || isFirstLoad(usersNetworkStatus),
  };
};
