import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ApolloCache, StoreObject, useMutation, useQuery } from '@apollo/client';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { Modifiers } from '@apollo/client/cache/core/types/common';

import {
  CostDateApprovalStatus,
  CostDateFragmentV2Fragment,
  CostDateStatus,
  CostManagement,
  CostManagementCostDatesFragmentV2Fragment,
  CostManagementFragmentV2Fragment,
  CostManagementInput,
  CostManagementResult,
  CostManagementsV2Query,
  CostManagementsV2QueryVariables,
  CurrenciesQuery,
  DeleteCostDatesMutation,
  DeleteCostDatesMutationVariables,
  MassEditCostManagementV2Mutation,
  MassEditCostManagementV2MutationVariables,
  PackageTypesQuery,
  PackageTypesQueryVariables,
  Query,
  SupplierAssortmentCostFilter,
  UpdateCostDatesApprovalStatusMutation,
  UpdateCostDatesApprovalStatusMutationVariables,
  UpdateCostDatesV2Mutation,
  UpdateCostDatesV2MutationVariables,
  UpdateGridData,
  UsersQuery,
  UsersQueryVariables,
} from 'shared/types';
import {
  AddModalFormValues,
  CommentsModalContext,
  DeleteCostDatesInput,
  MappedRow,
  PackageTypeIdNameMap,
  Row,
  SetupResult,
  UnsubmittedRows,
} from 'pages/CostManagementV2/types';
import { useEditPage, useOnGridChange, usePagination, useRole, useUploadFile } from 'shared/hooks';
import { ROLE_VENDOR } from 'shared/constants/access';
import {
  costManagementActionsUploadMutation,
  costManagementMassEditMutationV2,
  costManagementsQueryV2,
  deleteCostDatesMutation,
  fragmentCostDateV2,
  fragmentCostManagementCostDatesV2,
  fragmentCostManagementV2,
  updateCostDatesApprovalStatusMutation,
  updateCostDatesMutationV2,
  vendorCostManagementUploadMutation,
} from 'pages/CostManagementV2/operations';
import { mapDataToRows, mapRowToMutationArgs } from 'pages/CostManagementV2/mappers';
import {
  displayNameOptionMapper,
  isFirstLoad,
  mapToCurrenciesOptions,
  mapToLanguage,
  mapToSelectOptions,
  mergeFilter,
} from 'shared/utils';
import { currenciesQuery, packageTypesQuery, usersQuery } from 'shared/operations/query';
import { PackageTypeName } from 'shared/constants/package';
import { onUpdateError } from 'pages/CostManagementV2/handlers';
import { UseOnGridChangeResult } from 'shared/hooks/useOnGridChange/types';
import { SessionStorageContext } from 'setup/providers/Context/Context';
import { EXCEL_FILE_TYPES } from 'shared/tools/excel/constants';

import {
  ROLE_ASSIGNED_INTERNAL_USER,
  defaultDownloadLimit,
  paginationLimit,
  warningDownloadCount,
} from '../constants';
import { mapRowsForDownload } from '../mappers/mapRowsForDownload';
import { filterPermissions } from '../constants/filterPermissions';
import {
  getOnDownloadUploadActionsResultCallback,
  getUploadActionsModalUI,
} from '../components/UploadActionsModal';
import { mapDataGridRowsToMappedRows } from '../mappers/mapDataGridRowsToMappedRows';
import { exportCostManagementXlsxFile } from '../utils/exportCostManagementXlsxFile';
import {
  downloadCostManagementPromotionalCostTemplateExcel,
  downloadCostManagementRegularCostTemplateExcel,
} from '../utils/costManagementTemplateDownload';

import { useColumns } from './useColumns';

const defaultItems: Row[] = [];

const useSetup = (): SetupResult => {
  const role = useRole();

  const defaultFilter: CostManagementsV2QueryVariables['filter'] = {
    costDateStatuses: filterPermissions[role]?.vendorCostManagement.default,
    supplierAssortment:
      role === ROLE_VENDOR
        ? SupplierAssortmentCostFilter.Existing
        : SupplierAssortmentCostFilter.All,
  };

  const canUpload = role === ROLE_VENDOR || ROLE_ASSIGNED_INTERNAL_USER.has(role);
  const canApproveAndReject = ROLE_ASSIGNED_INTERNAL_USER.has(role);
  const canBulkDelete = ROLE_ASSIGNED_INTERNAL_USER.has(role);
  const canEditIsDisabled = role === ROLE_VENDOR;

  let deletableCostDateStatuses: Array<CostDateStatus> = [];
  if (role === ROLE_VENDOR) {
    deletableCostDateStatuses = [
      CostDateStatus.Draft,
      CostDateStatus.Pending,
      CostDateStatus.Rejected,
    ];
  } else if (ROLE_ASSIGNED_INTERNAL_USER.has(role)) {
    deletableCostDateStatuses = [
      CostDateStatus.Draft,
      CostDateStatus.Pending,
      CostDateStatus.Rejected,
      CostDateStatus.Future,
      CostDateStatus.Past,
      CostDateStatus.Current,
    ];
  }

  const hideIsDisabledColumn = role === ROLE_VENDOR;
  const hideCanAutoApproveColumn = role === ROLE_VENDOR;
  const enableEditRowWithStatusesWhitelist =
    role === ROLE_VENDOR
      ? [
          CostDateStatus.Rejected,
          CostDateStatus.Pending,
          CostDateStatus.Draft,
          CostDateStatus.Future,
          CostDateStatus.Undefined,
        ]
      : null;
  const canSubmit = role === ROLE_VENDOR;
  const shouldUseStickyColumns = role !== ROLE_VENDOR;
  const disablePastForDatePicker = role === ROLE_VENDOR;
  const shouldShowParentCompanyColumn = role !== ROLE_VENDOR;

  const [unsubmittedRows, setUnsubmittedRows] = useState<UnsubmittedRows>({});
  const [commentsRequired, setCommentsRequired] = useState<boolean>(false);
  const [shouldShowAddModal, setShouldShowAddModal] = useState<boolean>(false);
  const [mappedData, setMappedData] = useState<MappedRow[]>([]);
  const [isFirstLoadingFinish, setIsFirstLoadingFinish] = useState(false);
  const [filter, setFilter] = useState<CostManagementsV2QueryVariables['filter']>(defaultFilter);
  const [isLoading, setIsLoading] = useState(false);
  const { onPageChange, onRowsPerPageChange, pageIndex, pageSize, pagination, resetPageIndex } =
    usePagination();
  const { language } = useContext(SessionStorageContext);
  const { selectedRows, setSelectedRows, triggerUncheckAllCheckboxes, uncheckAllCheckboxes } =
    useEditPage<MappedRow>();

  const {
    data: { costManagementRecords: { records: data, totalCount } } = {
      costManagementRecords: {
        totalCount: 0,
        records: defaultItems,
      },
    },
    loading,
    networkStatus,
    client,
  } = useQuery<CostManagementsV2Query, CostManagementsV2QueryVariables>(costManagementsQueryV2, {
    variables: {
      pagination,
      filter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: { packageTypes } = {
      packageTypes: [],
    },
    networkStatus: packageTypesNetworkStatus,
    loading: packageTypesLoading,
  } = useQuery<PackageTypesQuery, PackageTypesQueryVariables>(packageTypesQuery);

  const { data: { currencies } = { currencies: [] }, networkStatus: currenciesNetworkStatus } =
    useQuery<CurrenciesQuery>(currenciesQuery);

  const { data: usersData } = useQuery<UsersQuery, UsersQueryVariables>(usersQuery, {
    skip: role === ROLE_VENDOR,
  });

  const [saveFormChanges, { loading: massEditMutationLoading }] = useMutation<
    MassEditCostManagementV2Mutation,
    MassEditCostManagementV2MutationVariables
  >(costManagementMassEditMutationV2, {
    update(cache) {
      cache.evict({
        fieldName: 'costManagementRecords',
        broadcast: true,
      });
    },
  });

  const [updateCostDatesApprovalStatus, { loading: updateCostDatesApprovalStatusLoading }] =
    useMutation<
      UpdateCostDatesApprovalStatusMutation,
      UpdateCostDatesApprovalStatusMutationVariables
    >(updateCostDatesApprovalStatusMutation, {});

  const updateCostDatesApprovalStatusCallback = (
    costDateIds: string[],
    status: CostDateApprovalStatus,
    comments?: string,
  ) =>
    updateCostDatesApprovalStatus({
      variables: {
        input: {
          ids: costDateIds,
          approvalStatus: status,
          comments,
        },
      },
      update(cache) {
        triggerUncheckAllCheckboxes();
        setSelectedRows([]);

        if (costDateIds.length >= pageSize) {
          cache.evict({
            fieldName: 'costManagementRecords',
            broadcast: true,
          });
        }

        const costManagementIds: string[] = [];
        costDateIds.forEach(costDateId => {
          const costDateFragementId = `CostDate:${costDateId}`;
          const { costManagementId } = cache.readFragment<CostDateFragmentV2Fragment>({
            id: costDateFragementId,
            fragment: fragmentCostDateV2,
            fragmentName: 'CostDateFragmentV2',
          })!;
          if (!costManagementIds.includes(costManagementId)) {
            costManagementIds.push(costManagementId);
          }
        });

        costManagementIds.forEach(costManagementId => {
          const newCostDates: CostDateFragmentV2Fragment[] = [];
          const costManagementFragmentId = `CostManagement:${costManagementId}`;
          const { costDates } = cache.readFragment<CostManagementCostDatesFragmentV2Fragment>({
            id: costManagementFragmentId,
            fragment: fragmentCostManagementCostDatesV2,
            fragmentName: 'CostManagementCostDatesFragmentV2',
          })!;

          costDates.forEach(costDate => {
            let clonedCostDate = { ...costDate };
            if (!costDateIds.includes(clonedCostDate.id)) {
              newCostDates.push(clonedCostDate);
            } else {
              let newStatus;
              const now = Date.now() / 1e3;
              const effectiveDate = costDate?.effectiveDate || 0;

              if (status === CostDateApprovalStatus.Pending) {
                newStatus = CostDateStatus.Pending;
              } else if (status === CostDateApprovalStatus.Rejected) {
                newStatus = CostDateStatus.Rejected;
              } else if (effectiveDate <= now) {
                newStatus = CostDateStatus.Current;
              } else {
                newStatus = CostDateStatus.Future;
              }
              clonedCostDate = { ...clonedCostDate, status: newStatus };
              newCostDates.push(clonedCostDate);
            }
          });

          let hasCurrentStatus = false;
          newCostDates.sort((a, b) => b.effectiveDate - a.effectiveDate);

          const finalCostDates: CostDateFragmentV2Fragment[] = [];
          newCostDates.forEach(costDate => {
            const clonedCostDate = costDate;
            if (costDate.status === CostDateStatus.Current) {
              if (hasCurrentStatus) {
                clonedCostDate.status = CostDateStatus.Past;
              } else {
                hasCurrentStatus = true;
              }
            }
            finalCostDates.push(clonedCostDate);
          });

          cache.writeFragment<CostManagementCostDatesFragmentV2Fragment>({
            id: costManagementFragmentId,
            data: {
              costDates: finalCostDates,
            },
            fragment: fragmentCostManagementCostDatesV2,
            fragmentName: 'CostManagementCostDatesFragmentV2',
          });
        });
      },
    });

  const [deleteCostDates, { loading: deleteCostDatesLoading }] = useMutation<
    DeleteCostDatesMutation,
    DeleteCostDatesMutationVariables
  >(deleteCostDatesMutation, {});

  const deleteCostDatesCallback = (costManagementIdsWithCostDateIds: DeleteCostDatesInput) => {
    const ids = Object.values(costManagementIdsWithCostDateIds).flat();
    void deleteCostDates({
      variables: {
        input: ids,
      },
      update(cache) {
        triggerUncheckAllCheckboxes();
        setSelectedRows([]);

        if (Object.values(costManagementIdsWithCostDateIds).length >= pageSize) {
          cache.evict({
            fieldName: 'costManagementRecords',
            broadcast: true,
          });
          return;
        }

        Object.entries(costManagementIdsWithCostDateIds).forEach(
          ([costManagementId, costDateIds]) => {
            const costManagementFragmentId = `CostManagement:${costManagementId}`;
            const { costDates: originalCostDates } =
              cache.readFragment<CostManagementCostDatesFragmentV2Fragment>({
                id: costManagementFragmentId,
                fragment: fragmentCostManagementCostDatesV2,
                fragmentName: 'CostManagementCostDatesFragmentV2',
              })!;

            const updatedCostDates = originalCostDates.filter(
              ({ id }) => !costDateIds.includes(id),
            );

            if (updatedCostDates.length === originalCostDates.length) {
              return;
            }

            if (updatedCostDates.length === 0) {
              cache.evict({
                id: costManagementFragmentId,
              });
            } else {
              cache.writeFragment<CostManagementCostDatesFragmentV2Fragment>({
                id: costManagementFragmentId,
                data: {
                  costDates: updatedCostDates,
                },
                fragment: fragmentCostManagementCostDatesV2,
                fragmentName: 'CostManagementCostDatesFragmentV2',
              });
            }
          },
        );
      },
    });
  };

  const packageTypeIdNameMap = useMemo<PackageTypeIdNameMap>(() => {
    const result: PackageTypeIdNameMap = {} as PackageTypeIdNameMap;
    packageTypes.forEach(({ id, name }) => {
      result[name as PackageTypeName] = id;
    });
    return result;
  }, [packageTypes]);

  const arbitraryMapperData = useMemo(() => ({ packageTypeIdNameMap }), [packageTypeIdNameMap]);

  useEffect(() => {
    if (!packageTypesLoading && data !== defaultItems) {
      setMappedData(
        mapDataToRows(data, packageTypeIdNameMap, canUpload, canEditIsDisabled, unsubmittedRows),
      );
      setIsFirstLoadingFinish(true);
    }
  }, [packageTypesLoading, data, packageTypeIdNameMap, canUpload, unsubmittedRows]);

  useEffect(() => {
    if (isFirstLoad(networkStatus)) {
      setIsFirstLoadingFinish(false);
    }
  }, [networkStatus]);

  const currenciesOptions = useMemo(() => mapToCurrenciesOptions(currencies), [currencies]);

  const updateFilter = useCallback((newFilter: CostManagementsV2QueryVariables['filter']) => {
    setFilter(prevFilter => {
      const finalFilter = mergeFilter(prevFilter, newFilter, defaultFilter);

      const isMissingCostView =
        finalFilter.supplierAssortment === SupplierAssortmentCostFilter.Missing;
      const isDefaultCostDateStatuses =
        finalFilter.costDateStatuses === defaultFilter.costDateStatuses;
      if (isMissingCostView && isDefaultCostDateStatuses) delete finalFilter.costDateStatuses;

      return finalFilter;
    });
    resetPageIndex();
  }, []);

  const onAddClick = useCallback(() => {
    setShouldShowAddModal(true);
  }, []);

  const onCloseAddModalClick = useCallback(() => {
    setShouldShowAddModal(false);
  }, []);

  const onSaveAndCloseAddModalClick = useCallback(
    ({ item, location, opco, supplier }: UnpackNestedValue<AddModalFormValues>) => {
      const input: CostManagementInput[] = [];

      item!.forEach(i => {
        supplier!.forEach(s => {
          if (!location || !location.length) {
            if (!opco || !opco.length) {
              input.push({
                itemId: i.value,
                supplierId: s.value,
                isDisabled: false,
              });
            } else {
              opco.forEach(o => {
                input.push({
                  itemId: i.value,
                  supplierId: s.value,
                  opcoId: o.value,
                  isDisabled: false,
                });
              });
            }
          } else {
            location.forEach(l => {
              if (!opco || !opco.length) {
                input.push({
                  itemId: i.value,
                  supplierId: s.value,
                  locationId: l.value,
                  isDisabled: false,
                });
              } else {
                opco.forEach(o => {
                  input.push({
                    itemId: i.value,
                    supplierId: s.value,
                    locationId: l.value,
                    opcoId: o.value,
                    isDisabled: false,
                  });
                });
              }
            });
          }
        });
      });

      setShouldShowAddModal(false);

      return saveFormChanges({
        variables: {
          input: {
            upsert: input,
          },
        },
      });
    },
    [],
  );

  const [shouldShowCommentsModal, setShouldShowCommentsModal] = useState<boolean>(false);
  const [commentsModalContext, setCommentsModalContext] = useState<CommentsModalContext>();

  const onOpenCommentsModalClick = useCallback(
    (costDateIds: string[], status: CostDateApprovalStatus) => {
      setShouldShowCommentsModal(true);
      setCommentsModalContext({ costDateIds, status });
    },
    [setShouldShowCommentsModal, setCommentsModalContext],
  );

  const onCloseCommentsModalClick = useCallback(() => {
    setShouldShowCommentsModal(false);
    setCommentsModalContext(undefined);
  }, [setShouldShowCommentsModal, setCommentsModalContext]);

  const onSubmitCommentsModalClick = useCallback(
    ({ comments }: { comments: string }) => {
      if (!commentsModalContext) return;
      const { costDateIds, status } = commentsModalContext;
      void updateCostDatesApprovalStatusCallback(costDateIds, status, comments);
      onCloseCommentsModalClick();
    },
    [commentsModalContext, updateCostDatesApprovalStatusCallback, onCloseCommentsModalClick],
  );

  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState<boolean>(false);
  const [deleteMessage, setDeleteMessage] = useState<ReactNode>();
  const [deleteCostDatesInput, setDeleteCostDatesInput] = useState<DeleteCostDatesInput>();

  const onOpenDeleteModalClick = useCallback(
    (input: DeleteCostDatesInput, message: ReactNode) => {
      setShouldShowDeleteModal(true);
      setDeleteMessage(message);
      setDeleteCostDatesInput(input);
    },
    [setShouldShowDeleteModal, setDeleteMessage, setDeleteCostDatesInput],
  );

  const onCloseDeleteModalClick = useCallback(() => {
    setShouldShowDeleteModal(false);
    setDeleteMessage(undefined);
    setDeleteCostDatesInput(undefined);
  }, [setShouldShowDeleteModal, setDeleteMessage, setDeleteCostDatesInput]);

  const onConfirmDeleteModalClick = useCallback(() => {
    if (!deleteCostDatesInput) return;
    deleteCostDatesCallback(deleteCostDatesInput);
    onCloseDeleteModalClick();
  }, [deleteCostDatesInput, deleteCostDatesCallback, onCloseDeleteModalClick]);

  const onBulkDeleteClick = useCallback(() => {
    const input = {} as DeleteCostDatesInput;
    const flattened = mapDataGridRowsToMappedRows(selectedRows);
    if (!flattened.length) return;

    flattened.forEach(row => {
      if (row.costId) {
        if (!input[row.costManagementId]) {
          input[row.costManagementId] = [];
        }
        input[row.costManagementId].push(row.costId);
      }
    });

    const message = <div>Are you sure to delete {flattened.length} effective dates?</div>;

    onOpenDeleteModalClick(input, message);
  }, [mapDataGridRowsToMappedRows, selectedRows, onOpenDeleteModalClick]);

  const updateIsDisabled = useCallback<UpdateGridData<MappedRow, boolean>>(
    (index, columnId, value, original) =>
      saveFormChanges({
        variables: {
          input: {
            upsert: [
              {
                itemId: original.internalItemId!,
                supplierId: original.internalSupplierId!,
                locationId: original.internalLocationId,
                opcoId: original.opcoId,
                isDisabled: value,
              },
            ],
          },
        },
      }),
    [],
  );

  const userOptions = useMemo(
    () =>
      usersData?.users?.length ? mapToSelectOptions(usersData.users, displayNameOptionMapper) : [],
    [usersData],
  );

  const columns = useColumns({
    canSubmit,
    enableEditRowWithStatusesWhitelist,
    hideIsDisabledColumn,
    canEditIsDisabled,
    hideCanAutoApproveColumn,
    deletableCostDateStatuses,
    canApproveAndReject,
    canUpload,
    currenciesOptions,
    updateFilter,
    userOptions,
    updateIsDisabled,
    updateCostDatesApprovalStatusCallback,
    setUnsubmittedRows,
    onOpenCommentsModalClick,
    onOpenDeleteModalClick,
    languageCode: mapToLanguage.get(language) || 'en-US',
    role,
    shouldUseStickyColumns,
    disablePastForDatePicker,
    shouldShowParentCompanyColumn,
  });

  const uploadFileProps =
    role === ROLE_VENDOR
      ? useUploadFile({
          mutation: vendorCostManagementUploadMutation,
          client,
          evictCacheForQueryName: 'costManagementRecords',
          fileType: 'excel',
          acceptedFileTypes: EXCEL_FILE_TYPES,
          templates: [
            {
              name: 'REGULAR COST TEMPLATE',
              downloadCallback: downloadCostManagementRegularCostTemplateExcel,
            },
            {
              name: 'PROMOTIONAL COST TEMPLATE',
              downloadCallback: downloadCostManagementPromotionalCostTemplateExcel,
            },
          ],
        })
      : useUploadFile({
          mutation: costManagementActionsUploadMutation,
          client,
          evictCacheForQueryName: 'costManagementRecords',
          fileType: 'excel',
          acceptedFileTypes: EXCEL_FILE_TYPES,
          getCustomUploadUI: getUploadActionsModalUI,
          onDownloadResult: useMemo(
            () =>
              getOnDownloadUploadActionsResultCallback(
                hideIsDisabledColumn,
                hideCanAutoApproveColumn,
                shouldShowParentCompanyColumn,
              ),
            [hideIsDisabledColumn, hideCanAutoApproveColumn, shouldShowParentCompanyColumn],
          ),
        });

  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [shouldShowDownloadModal, setShouldShowDownloadModal] = useState<boolean>(false);
  const [rowsToDownloadCount, setRowsToDownload] = useState<number>(0);
  useState<number>(defaultDownloadLimit);

  const downloadCostManagement = (variables: CostManagementsV2QueryVariables) =>
    client.query<CostManagementsV2Query, CostManagementsV2QueryVariables>({
      query: costManagementsQueryV2,
      variables,
      // no-cache required to prevent download missfires
      fetchPolicy: 'no-cache',
    });

  const onDownloadCostManagement = useCallback(async () => {
    if (!rowsToDownloadCount) return;
    setIsDownloading(true);
    let limit = Math.min(rowsToDownloadCount, paginationLimit);
    const promises = [];

    for (let offset = 0; offset < rowsToDownloadCount; offset += paginationLimit) {
      limit = Math.min(rowsToDownloadCount - offset, paginationLimit);
      const itemSourceIds = selectedRows.reduce((itemSourceIdsResult, row) => {
        if (row.original.costManagementId === '0' && row.original.itemSourceIds?.length) {
          itemSourceIdsResult.push(...row.original.itemSourceIds);
        }
        return itemSourceIdsResult;
      }, [] as string[]);

      const variables = {
        filter: {
          ...filter,
          id: selectedRows.map(row => row.original.costManagementId),
          itemSourceIDs: itemSourceIds.length ? itemSourceIds : undefined,
        },
        pagination: {
          limit,
          offset,
        },
      };
      promises.push(downloadCostManagement(variables));
    }

    try {
      const results = await Promise.all(promises);
      const records = results.flatMap(result => result?.data?.costManagementRecords?.records || []);

      const downloadRows = mapDataToRows(records, packageTypeIdNameMap, false, canEditIsDisabled);
      const downloadData = mapRowsForDownload(
        downloadRows,
        currencies,
        hideIsDisabledColumn,
        hideCanAutoApproveColumn,
        shouldShowParentCompanyColumn,
      );
      exportCostManagementXlsxFile(
        downloadData,
        `Vendor Cost Management (${new Date().toLocaleString()})`,
        'cost',
      );
    } finally {
      triggerUncheckAllCheckboxes();
      setIsDownloading(false);
    }
  }, [rowsToDownloadCount, filter, pagination, selectedRows]);

  useEffect(() => {
    setIsLoading(loading || isDownloading);
  }, [loading, isDownloading]);

  useEffect(() => {
    setRowsToDownload(
      selectedRows.length > 0 ? selectedRows.length : Math.min(totalCount, defaultDownloadLimit),
    );
  }, [selectedRows, totalCount]);

  const onDataGridDownloadButtonClick = () => {
    if (rowsToDownloadCount >= warningDownloadCount) {
      setShouldShowDownloadModal(true);
    } else {
      void onDownloadCostManagement();
    }
  };

  const onCloseDownloadModalClick = useCallback(() => {
    setShouldShowDownloadModal(false);
  }, []);

  const onConfirmDownloadModalClick = () => {
    void onDownloadCostManagement();
    setShouldShowDownloadModal(false);
  };

  const [onChangeDefault, mutationLoading] = useOnGridChange<
    Row,
    UpdateCostDatesV2Mutation,
    UpdateCostDatesV2MutationVariables,
    MappedRow
  >({
    checkEqualityBeforeSendRequest: false,
    data,
    mutation: updateCostDatesMutationV2,
    mapRowToMutationArgs,
    arbitraryMapperData,
    update(cache, updateData) {
      const updateCostDates = updateData?.data?.updateCostDates;
      if (!updateCostDates) return;

      const [updateCostDate] = updateCostDates;

      const id = `CostManagement:${updateCostDate.costManagementId}`;
      const { costDates } = cache.readFragment<CostManagementCostDatesFragmentV2Fragment>({
        id,
        fragment: fragmentCostManagementCostDatesV2,
        fragmentName: 'CostManagementCostDatesFragmentV2',
      })!;

      if (!costDates.some(costDate => costDate.id === updateCostDate.id)) {
        cache.writeFragment<CostManagementCostDatesFragmentV2Fragment>({
          id,
          data: {
            costDates: [
              ...costDates,
              {
                ...updateCostDate,
                commentedByDisplayName: '',
                comments: '',
              },
            ],
          },
          fragment: fragmentCostManagementCostDatesV2,
          fragmentName: 'CostManagementCostDatesFragmentV2',
        });
      }
    },
    onError: onUpdateError,
  });

  const replaceCostManagementRowInCache = useCallback(
    (
      cache: ApolloCache<any>,
      oldCostManagementRowId: string,
      newCostManagement: CostManagement,
      fieldsToKeep?: Array<keyof CostManagement>,
    ) => {
      const oldId = `CostManagement:${oldCostManagementRowId}`;
      const oldData = cache.readFragment<CostManagementFragmentV2Fragment>({
        id: oldId,
        fragment: fragmentCostManagementV2,
        fragmentName: 'CostManagementFragmentV2',
      });

      const newId = cache.identify(newCostManagement as StoreObject);
      if (fieldsToKeep?.length) {
        const fields = fieldsToKeep.reduce((acc, field) => {
          acc[field] = () => oldData?.[field];
          return acc;
        }, {} as Modifiers);

        cache.modify({
          id: newId,
          fields,
        });
      }

      // Using newData instead of newCostManagement to make sure
      // using the latest data from cache
      const newData = cache.readFragment<CostManagementFragmentV2Fragment>({
        id: newId,
        fragment: fragmentCostManagementV2,
        fragmentName: 'CostManagementFragmentV2',
      });

      cache.updateQuery(
        {
          query: costManagementsQueryV2,
          // For the same costManagementQuery, there are multiple cache entries by variables
          // get the cache entry used by current filter and pagination
          variables: {
            pagination,
            filter,
          },
        },
        (existingCostManagementQueryCache: Pick<Query, 'costManagementRecords'> | null) => {
          if (!existingCostManagementQueryCache?.costManagementRecords?.records)
            return existingCostManagementQueryCache;

          const records = existingCostManagementQueryCache.costManagementRecords.records.map(
            existingCostManagementRow => {
              const rowId = cache.identify(existingCostManagementRow);
              return rowId === oldId ? newData : existingCostManagementRow;
            },
          );
          return {
            costManagementRecords: {
              ...existingCostManagementQueryCache.costManagementRecords,
              records: records as CostManagementResult['records'],
            },
          };
        },
      );

      cache.evict({ id: oldId });
    },
    [pagination, filter],
  );

  const onChange = useCallback<UseOnGridChangeResult<MappedRow>[0]>(
    async (...updateArgs) => {
      const [
        ,
        columnName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        newValue,
        row,
        gridRow,
      ] = updateArgs;
      if (
        !row.isUnsubmitted ||
        (columnName !== 'currency' &&
          columnName !== 'effectiveDate' &&
          columnName !== 'type' &&
          columnName !== 'endDate') ||
        row[PackageTypeName.Each]! > 0 ||
        row[PackageTypeName.Inner]! > 0 ||
        row[PackageTypeName.Case]! > 0 ||
        row[PackageTypeName.MasterCase]! > 0 ||
        row[PackageTypeName.Pallet]! > 0
      ) {
        if (row.isUnsubmitted) {
          setUnsubmittedRows(oldValue => {
            const newUnsubmittedRowsValue: UnsubmittedRows = { ...oldValue };
            delete newUnsubmittedRowsValue[row.parent!.id];
            return newUnsubmittedRowsValue;
          });
        }

        if (row.costManagementId === '0' && row.internalItemId && row.internalSupplierId) {
          await saveFormChanges({
            variables: {
              input: {
                upsert: [
                  {
                    itemId: row.internalItemId,
                    supplierId: row.internalSupplierId,
                    isDisabled: false,
                  },
                ],
              },
            },
            update(cache, newCostManagementData) {
              const newCostManagement = newCostManagementData.data?.massEditCostManagement?.[0];

              if (!newCostManagement) return;

              replaceCostManagementRowInCache(
                cache,
                row.id,
                newCostManagement as unknown as CostManagement,
                // The newly created cost management doesn't contain any preload fields
                // such as item source. So they need to be added back
                ['itemSources'],
              );

              // Use the newly created id to add cost dates to
              row.id = newCostManagement.id;
              row.costManagementId = newCostManagement.id;
              gridRow.id = newCostManagement.id;
            },
          });
        }

        onChangeDefault(...updateArgs);
      } else {
        setUnsubmittedRows(oldValue => ({
          ...oldValue,
          [row.parent!.id]: oldValue[row.parent!.id].map(unsubmittedRow =>
            unsubmittedRow.id === row.id
              ? {
                  ...unsubmittedRow,
                  // @ts-ignore
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  [columnName]: newValue,
                }
              : unsubmittedRow,
          ),
        }));
      }
    },
    [onChangeDefault],
  );

  const [shouldShowApproveOrRejectWarningModal, setShouldShowApproveOrRejectWarningModal] =
    useState<boolean>(false);
  const [approveOrRejectWarningMessage, setApproveOrRejectWarningMessage] = useState<string>('');
  const [bulkApproveOrRejectStatus, setBulkApproveOrRejectStatus] =
    useState<CostDateApprovalStatus>();

  const onCloseApproveOrRejectWarningModalClick = useCallback(() => {
    setShouldShowApproveOrRejectWarningModal(false);
    setApproveOrRejectWarningMessage('');
    setBulkApproveOrRejectStatus(undefined);
  }, [
    setShouldShowApproveOrRejectWarningModal,
    setApproveOrRejectWarningMessage,
    setBulkApproveOrRejectStatus,
  ]);

  const onConfirmApproveOrRejectWarningModalClick = useCallback(
    (status?: CostDateApprovalStatus.Approved | CostDateApprovalStatus.Rejected) => {
      onCloseApproveOrRejectWarningModalClick();

      const statusToUse = typeof status === 'string' ? status : bulkApproveOrRejectStatus;

      if (!statusToUse) return;

      const flattened = mapDataGridRowsToMappedRows(selectedRows);

      const selectedPendingCostDates = flattened.filter(
        row => row.status === CostDateStatus.Pending && row.costId,
      );

      if (!selectedPendingCostDates.length) return;

      const costDateIds = selectedPendingCostDates.map(row => row.costId!);
      if (status === CostDateApprovalStatus.Rejected) {
        setCommentsRequired(true);
      } else {
        setCommentsRequired(false);
      }

      onOpenCommentsModalClick(costDateIds, statusToUse);
    },
    [
      onCloseApproveOrRejectWarningModalClick,
      mapDataGridRowsToMappedRows,
      selectedRows,
      onOpenCommentsModalClick,
      bulkApproveOrRejectStatus,
    ],
  );

  const onBulkApproveOrRejectClick = useCallback(
    (status: CostDateApprovalStatus.Approved | CostDateApprovalStatus.Rejected) => {
      const flattened = mapDataGridRowsToMappedRows(selectedRows);
      if (!flattened.length) return;

      const selectedPendingCostDates = flattened.filter(
        row => row.status === CostDateStatus.Pending && row.costId,
      );

      if (!selectedPendingCostDates.length) {
        setShouldShowApproveOrRejectWarningModal(true);
        setApproveOrRejectWarningMessage(
          `You selected ${flattened.length} costs, but no cost is in ${CostDateStatus.Pending} status.`,
        );
        return;
      }

      if (flattened.length !== selectedPendingCostDates.length) {
        setShouldShowApproveOrRejectWarningModal(true);
        setApproveOrRejectWarningMessage(
          `You selected ${flattened.length} costs, but only ${selectedPendingCostDates.length} are in ${CostDateStatus.Pending} status. Do you want to only approve/reject those ${selectedPendingCostDates.length}?`,
        );
        setBulkApproveOrRejectStatus(status);
        return;
      }

      if (status === CostDateApprovalStatus.Rejected) {
        setCommentsRequired(true);
      } else {
        setCommentsRequired(false);
      }

      onConfirmApproveOrRejectWarningModalClick(status);
    },
    [
      mapDataGridRowsToMappedRows,
      selectedRows,
      setShouldShowApproveOrRejectWarningModal,
      setApproveOrRejectWarningMessage,
      setBulkApproveOrRejectStatus,
      onConfirmApproveOrRejectWarningModalClick,
    ],
  );

  return {
    isLoading,
    canSubmit,
    canBulkDelete,
    canApproveAndReject,
    canUpload,
    data: mappedData,
    columns,
    commentsRequired,
    onAddClick,
    areSomeRowsSelected: selectedRows.length > 0,
    onBulkApproveOrRejectClick,
    shouldShowApproveOrRejectWarningModal,
    approveOrRejectWarningMessage,
    onCloseApproveOrRejectWarningModalClick,
    onConfirmApproveOrRejectWarningModalClick,
    onBulkDeleteClick,
    onCloseAddModalClick,
    onChange,
    onDataGridDownloadButtonClick,
    downloadButtonDisabled: isDownloading,
    onSaveAndCloseAddModalClick,
    loadingMoreData:
      loading ||
      mutationLoading ||
      massEditMutationLoading ||
      updateCostDatesApprovalStatusLoading ||
      deleteCostDatesLoading,
    showMainLoader:
      isFirstLoad(networkStatus) ||
      isFirstLoad(packageTypesNetworkStatus) ||
      isFirstLoad(currenciesNetworkStatus) ||
      (totalCount !== 0 && !mappedData.length) ||
      !isFirstLoadingFinish,
    shouldShowAddModal,
    totalCount,
    rowsToDownloadCount,
    onPageChange,
    onRowsPerPageChange,
    pageIndex,
    pageSize,
    setSelectedRows,
    uncheckAllCheckboxes,
    uploadFileProps,
    shouldShowCommentsModal,
    onCloseCommentsModalClick,
    onSubmitCommentsModalClick,
    commentsModalContext,
    shouldShowDeleteModal,
    onCloseDeleteModalClick,
    onConfirmDeleteModalClick,
    deleteMessage,
    shouldShowDownloadModal,
    onCloseDownloadModalClick,
    onConfirmDownloadModalClick,
    updateFilter,
    defaultFilter,
    updateCostDatesApprovalStatusLoading,
    deleteCostDatesLoading,
    selectedRows,
  };
};

export { useSetup };
