import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import classnames from 'classnames';
import FileIcon from '@mui/icons-material/InsertDriveFileRounded';

import { downloadBase64StringAsFile } from 'shared/utils';

import { UploadedFileSummaryProps } from './types';
import styles from './UploadedFileSummary.module.css';

const UploadedFileSummary = ({ onDownloadResult, uploadedFile }: UploadedFileSummaryProps) => {
  // eslint-disable-next-line prefer-const
  let { errors, file, rows, success, total, summary } = uploadedFile ?? {};

  if (summary) {
    errors = summary.errors;
    success = summary.success;
    total = summary.total;
  }

  const onDownload: () => void = useCallback(
    () =>
      onDownloadResult
        ? onDownloadResult(uploadedFile as unknown as Record<string, unknown>)
        : downloadBase64StringAsFile(file?.content, file?.name, 'excel'),
    [file, onDownloadResult, rows, uploadedFile],
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={classnames(styles.uploaded, {
              [styles.highFlex]: !errors,
            })}
          >
            Uploaded File
          </div>
          {!!errors && <div className={styles.errors}>Errors</div>}
          <div className={styles.success}>Success</div>
          <div className={styles.total}>Total</div>
        </div>
        <div className={styles.wrapper}>
          <div
            className={classnames(styles.uploaded, {
              [styles.highFlex]: !errors,
            })}
          >
            {file?.name && (
              <div className={classnames(styles.uploadedFile, styles.plainText)}>
                <FileIcon className={styles.fileIcon} />
                {file?.name}
              </div>
            )}
          </div>
          {!!errors && <div className={styles.errors}>{errors}</div>}
          <div className={styles.success}>{success}</div>
          <div className={classnames(styles.total, styles.plainText)}>{total}</div>
        </div>
      </div>
      {errors ? (
        <div className={styles.importWithErrors}>
          <div>
            <Typography variant="bold1">Import Completed</Typography>
            <Typography fontWeight="400" variant="h6">
              Please, review the
              <Typography component="span" variant="h6">
                {` ${errors} ${errors === 1 ? 'Error' : 'Errors'} .`}
              </Typography>
              The system cannot upload files with Errors, download files with Errors and fix them.
              After that repeat downloading process one more time.
            </Typography>
          </div>
          <Button className={styles.downloadButton} onClick={onDownload}>
            Download
          </Button>
        </div>
      ) : (
        <div className={styles.successImport}>
          <Typography variant="bold1">Import Completed</Typography>
          <Typography className={styles.plainText} variant="h6">
            Please, review the results on page
          </Typography>
        </div>
      )}
    </>
  );
};

export { UploadedFileSummary };
