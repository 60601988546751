import { FC } from 'react';

import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { DataGridPage } from 'shared/components/DataGridPage';
import { DataGrid } from 'shared/components/DataGrid';
import { Loader } from 'shared/components/Loader';

import { VendorCostManagementDefaultViewProps } from './types';

const VendorCostManagementDefaultView: FC<VendorCostManagementDefaultViewProps> = ({
  showMainLoader,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          hasAccessToUploadFile={false}
          title="Vendor Cost Management Default"
        />
      }
    >
      {showMainLoader ? (
        <Loader isLoading />
      ) : (
        <DataGrid
          {...props}
          disableOnLoadingMoreData
          enableExpanding
          showPagination={false}
          testId="vendor-cost-management-default-data-grid"
        />
      )}
    </DataGridPage>
  </>
);

export { VendorCostManagementDefaultView };
