import { exportXlsxFile } from 'shared/tools/excel/exportXlsxFile';

import { constants } from '../constants';

export const downloadTemplate = () => {
  const input = {
    fileName: `Item Extra Sub Class Default (${new Date().toLocaleString()})`,
    sheets: [
      {
        sheetName: 'data',
        tables: [
          {
            headers: [
              constants.download.columns.headers.subClass,
              constants.download.columns.headers.pullByDate,
              constants.download.columns.headers.markdownDays,
              constants.download.columns.headers.captureExpiry,
              constants.download.columns.headers.acceptableShelfLife,
              constants.download.columns.headers.packingConstraint,
            ],
            rows: [],
          },
        ],
      },
    ],
  };

  return exportXlsxFile(input);
};
