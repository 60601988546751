import { ItemSupplierHierarchyRow, MappedRow } from '../types';

export const mapDataToRows = (itemSupplierHierarchies: ItemSupplierHierarchyRow[]): MappedRow[] => {
  if (!itemSupplierHierarchies.length) return [];

  const mappedRows = itemSupplierHierarchies.map(itemSupplierHierarchy => {
    const mappedData: MappedRow = {
      id: itemSupplierHierarchy.id,
      departmentName: itemSupplierHierarchy.itemDepartment?.name,
      departmentId:
        itemSupplierHierarchy.itemDepartment?.id ??
        itemSupplierHierarchy.itemClass?.itemDepartment?.id,
      className: itemSupplierHierarchy.itemClass?.name,
      userAssignments: itemSupplierHierarchy.userAssignment,
    };

    return mappedData;
  });

  mappedRows.sort((a, b) => {
    if (a.departmentName < b.departmentName) return -1;
    if (a.departmentName > b.departmentName) return 1;
    if (a.className < b.className) return -1;
    if (a.className > b.className) return 1;
    return 0;
  });

  const departmentIdToRowMap: Record<string, MappedRow[]> = {};

  mappedRows
    .filter(m => m.className)
    .forEach(row => {
      if (!departmentIdToRowMap[row.departmentId]) {
        departmentIdToRowMap[row.departmentId] = [];
      }

      departmentIdToRowMap[row.departmentId].push(row);
    });

  const parentRows = mappedRows.filter(row => !row.className);

  for (let i = 0; i < parentRows.length; i++) {
    const parentRow = parentRows[i];
    parentRow.rows = departmentIdToRowMap[parentRow.departmentId];

    for (let j = 0; j < parentRow.rows?.length; j++) {
      parentRow.rows[j].parent = parentRow;
    }
  }

  return parentRows;
};
