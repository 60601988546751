export const ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST =
  'ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST';
export const ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY =
  'ACTION_EDIT_DESTINATION_ASSORTMENT_NATIONAL_COST_CURRENCY';
export const ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST =
  'ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST';
export const ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY =
  'ACTION_EDIT_DESTINATION_ASSORTMENT_LOCAL_COST_CURRENCY';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_NATIONAL_ASSORTMENT_CODE';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALLOCATION_PRIORITY';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_CUSTOM_ASSORTMENT_CODE';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_ALCOHOL_TYPE';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_TOBACCO_TYPE';
export const ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES =
  'ACTION_EDIT_USER_DEFINED_ATTRIBUTES_LOCATION_ITEM_CAPABILITIES';
export const ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT =
  'ACTION_UPLOAD_FILE_DESTINATION_ASSORTMENT';
export const ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT = 'ACTION_UPLOAD_FILE_SUPPLIER_ASSORTMENT';
export const ACTION_UPLOAD_FILE_SOURCE_DESTINATION = 'ACTION_UPLOAD_FILE_SOURCE_DESTINATION';
export const ACTION_UPLOAD_FILE_TRANSPORTATION_LANE = 'ACTION_UPLOAD_FILE_TRANSPORTATION_LANE';
export const ACTION_UPLOAD_FILE_LOCATION = 'ACTION_UPLOAD_FILE_LOCATION';
export const ACTION_UPLOAD_FILE_ITEM = 'ACTION_UPLOAD_FILE_ITEM';
export const ACTION_UPLOAD_FILE_ITEM_SOURCE = 'ACTION_UPLOAD_FILE_ITEM_SOURCE';
export const ACTION_MASS_EDIT_ITEM = 'ACTION_MASS_EDIT_ITEM';
export const ACTION_MASS_DESTINATION_ASSORTMENT = 'ACTION_MASS_DESTINATION_ASSORTMENT';
export const ACTION_MASS_EDIT_LOCATION = 'ACTION_MASS_EDIT_LOCATION';
export const ACTION_EDIT_LOCATION = 'ACTION_EDIT_LOCATION';
export const ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT = 'ACTION_MASS_EDIT_SUPPLIER_ASSORTMENT';
export const ACTION_MASS_EDIT_TRANSPORTATION_LANE = 'ACTION_MASS_EDIT_TRANSPORTATION_LANE';
export const ACTION_MASS_EDIT_ITEM_SOURCE = 'ACTION_MASS_EDIT_ITEM_SOURCE';
export const ACTION_MASS_EDIT_SOURCE_DESTINATION = 'ACTION_MASS_EDIT_SOURCE_DESTINATION';
export const ACTION_UPDATE_DESTINATION_ASSORTMENT = 'ACTION_UPDATE_DESTINATION_ASSORTMENT';
export const ACTION_VIEW_PAGE_ALL = 'ACTION_VIEW_PAGE_ALL';
export const ACTION_VIEW_PAGE_LOCATION = 'ACTION_VIEW_PAGE_LOCATION';
export const ACTION_VIEW_PAGE_ITEM = 'ACTION_VIEW_PAGE_ITEM';
export const ACTION_VIEW_PAGE_SOURCE_DESTINATION = 'ACTION_VIEW_PAGE_SOURCE_DESTINATION';
export const ACTION_VIEW_PAGE_USER_DEFINED_ATTRIBUTES = 'ACTION_VIEW_PAGE_USER_DEFINED_ATTRIBUTES';
export const ACTION_VIEW_PAGE_ITEM_EXTRA_SUB_CLASS_DEFAULT =
  'ACTION_VIEW_PAGE_ITEM_EXTRA_SUB_CLASS_DEFAULT';
export const ACTION_VIEW_PAGE_VENDOR_COST_MANAGEMENT_DEFAULT =
  'ACTION_VIEW_PAGE_VENDOR_COST_MANAGEMENT_DEFAULT';
export const ACTION_VIEW_PAGE_BOM = 'ACTION_VIEW_PAGE_BOM';
export const ACTION_VIEW_PAGE_DESTINATION_ASSORTMENT = 'ACTION_VIEW_PAGE_DESTINATION_ASSORTMENT';
export const ACTION_VIEW_PAGE_TRANSPORTATION_LANE = 'ACTION_VIEW_PAGE_TRANSPORTATION_LANE';
export const ACTION_VIEW_PAGE_ITEM_SOURCE = 'ACTION_VIEW_PAGE_ITEM_SOURCE';
export const ACTION_VIEW_PAGE_SUPPLIERS = 'ACTION_VIEW_PAGE_SUPPLIERS';
export const ACTION_VIEW_PAGE_SUPPLIER_ASSORTMENT = 'ACTION_VIEW_PAGE_SUPPLIER_ASSORTMENT';
export const ACTION_VIEW_PAGE_COST_MANAGEMENT = 'ACTION_VIEW_PAGE_COST_MANAGEMENT';
export const ACTION_VIEW_PAGE_COST_MANAGEMENT_V2 = 'ACTION_VIEW_PAGE_COST_MANAGEMENT_V2';
export const ACTION_VIEW_PAGE_DEAL_MANAGEMENT = 'ACTION_VIEW_PAGE_DEAL_MANAGEMENT';
export const ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL = 'ACTION_VIEW_PAGE_DEAL_MANAGEMENT_DETAIL';
export const ACTION_VIEW_PAGE_RESOURCES = 'ACTION_VIEW_PAGE_RESOURCES';
