export const PAGE_ALL = '*';
export const PAGE_ITEM = '/item';
export const PAGE_LOCATION = '/location';
export const PAGE_SUPPLIERS = '/suppliers';
export const PAGE_TRANSPORTATION_LANE = '/transportation-lane';
export const PAGE_SOURCE_DESTINATION = '/source-destination';
export const PAGE_USER_DEFINED_ATTRIBUTES = '/user-defined-attribute';
export const PAGE_ITEM_EXTRA_SUB_CLASS_DEFAULT = '/item-extra-sub-class-default';
export const PAGE_VENDOR_COST_MANAGEMENT_DEFAULT = '/vendor-cost-management-default';
export const PAGE_BOM = '/bom';
export const PAGE_DESTINATION_ASSORTMENT = '/destination-assortment';
export const PAGE_ITEM_SOURCE = '/item-source';
export const PAGE_SUPPLIER_ASSORTMENT = '/supplier-assortment';
export const PAGE_COST_MANAGEMENT = '/cost-management';
export const PAGE_ITEM_SOURCE_COST = '/item-source-cost';
export const PAGE_ITEM_SOURCE_COST_CURRENCY = '/item-source-cost-currency';
export const PAGE_ITEM_SOURCE_COST_NATIONAL = '/item-source-cost-national';
export const PAGE_ITEM_SOURCE_COST_NATIONAL_CURRENCY = '/item-source-cost-national-currency';
export const PAGE_ITEM_SOURCE_COST_LOCAL = '/item-source-cost-local';
export const PAGE_ITEM_SOURCE_COST_LOCAL_CURRENCY = '/item-source-cost-local-currency';
export const PAGE_COST_MANAGEMENT_V2 = '/vendor-cost-management';
export const PAGE_DEAL_MANAGEMENT = '/deal-management';
export const PAGE_DEAL_MANAGEMENT_DETAIL = `${PAGE_DEAL_MANAGEMENT}/:id`;
export const PAGE_RESOURCES = '/resources';
