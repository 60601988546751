import { FC } from 'react';

import { HeaderDataGridActions } from 'shared/components/HeaderDataGridActions';
import { DataGridPage } from 'shared/components/DataGridPage';
import { DataGrid } from 'shared/components/DataGrid';
import { Loader } from 'shared/components/Loader';

import { ItemExtraSubClassDefaultViewProps } from './types';
import { constants } from './constants';

const ItemExtraSubClassDefaultView: FC<ItemExtraSubClassDefaultViewProps> = ({
  showMainLoader,
  onDataGridDownloadButtonClick,
  uploadFileProps,
  ...props
}) => (
  <>
    <DataGridPage
      header={
        <HeaderDataGridActions
          downloadButtonDisabled={showMainLoader}
          hasAccessToUploadFile
          onDataGridDownloadButtonClick={onDataGridDownloadButtonClick}
          title="Item Extra Sub Class Default"
          uploadFileProps={uploadFileProps}
        />
      }
    >
      {showMainLoader ? (
        <Loader isLoading />
      ) : (
        <DataGrid
          {...props}
          disableOnLoadingMoreData
          rowsPerPageOptions={[constants.rowsPerPage]}
          testId="item-extra-sub-class-default-data-grid"
        />
      )}
    </DataGridPage>
  </>
);

export { ItemExtraSubClassDefaultView };
